import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { IVoidReason } from '@trolley/types';
import { trolleyClient } from '@trolley/api-sdk';

export const useVoidReasons = defineStore('VoidReasons', () => {
  const state = ref<{
    loading: boolean;
    reasons: IVoidReason[];
  }>({
    loading: true,
    reasons: [],
  });

  const fetchVoidReasons = async () => {
    state.value.loading = true;

    const { data } = await trolleyClient.voidReasons.getApiPosVoidReasons();
    state.value.reasons = data;

    state.value.loading = false;
  };

  return {
    state,
    fetchVoidReasons,
  };
});
