<template>
  <v-btn
    icon="mdi mdi-translate-variant"
    @click="changeLanguage"
    class="float"
    color="primary"
    label="demo"
  ></v-btn>
</template>

<script>
export default {
  methods: {
    changeLanguage() {
      const lang = this.$i18n.locale;
      console.log(lang);
      if (lang == "ar") {
        this.$i18n.locale = "en";
        localStorage.setItem("lang", "en");
      } else {
        this.$i18n.locale = "ar";
        localStorage.setItem("lang", "ar");
      }
    },
  },
};
</script>

<style scoped>
.float {
  position: fixed;
  display: none;
  bottom: 250px; /* adjust as needed */
  right: 20px; /* adjust as needed */
  z-index: 999; /* adjust as needed */
}
</style>
