import { defineStore } from 'pinia';
import {
  assignUniqueCodeGeneratorConfig,
  trolleyClient,
} from '@trolley/api-sdk';
import { ref, watch } from 'vue';
import type { ISettings } from '@trolley/types';
import { useLocalStorage } from '@vueuse/core';
import { subscribe } from '@enegix/events';

export const latestVersion = ref();

export const useSettings = defineStore('settings', () => {
  const settings = ref<ISettings | null>(null);

  const fetchSettings = async () => {
    const { data } = (await trolleyClient.settings.getApiPosSyncSettings()) as {
      data: ISettings;
    };
    settings.value = data;
  };

  // after sync, data might change. so we fetch to consume the new cached data.
  subscribe('interval-sync-settings-health', fetchSettings);

  watch(
    settings,
    (newSettings) => {
      if (!newSettings) return;

      assignUniqueCodeGeneratorConfig({
        warehouseId: newSettings.warehouse_id,
        terminalSequence: newSettings.sequence,
      });

      latestVersion.value = newSettings.version;
    },
    {
      deep: true,
      immediate: true,
    },
  );

  return {
    settings,
    fetchSettings,
  };
});

export const appVersion = useLocalStorage('appVersion', '', {
  writeDefaults: true,
});
