import { toast } from 'vue3-toastify';
import {
  PaymentStrategy,
  SinglePaymentStrategy,
  SplitPaymentStrategy,
} from './strategies';
import { AddedPayment, IPaymentBase, PAYMENT_METHODS } from '@trolley/types';
import { ItemsManager } from '@trolley/items';
import { toFloat, validateIntegerInput } from '@trolley/utils';

export class Payment implements IPaymentBase {
  key: number;
  id: number;
  payment_method_id: number;
  amount: number;
  extra: number;
  readable_payment_method: string;

  constructor(payment: IPaymentBase) {
    this.key = Math.floor(100000 + Math.random() * 900000);
    this.id = payment.id;
    this.payment_method_id = payment.payment_method_id;
    this.amount = toFloat(payment.amount);
    this.extra = payment.extra;
    this.readable_payment_method = payment.readable_payment_method;
  }
}

export class PaymentsManager {
  get payments() {
    return this.strategy.payments;
  }
  get totalPaidAmount() {
    return this.strategy.totalPaidAmount;
  }
  get isSubmittable() {
    return this.strategy.isSubmittable;
  }
  strategy: PaymentStrategy;
  itemsManager: ItemsManager;

  constructor(itemsManager: ItemsManager) {
    this.itemsManager = itemsManager;
    this.strategy = new SinglePaymentStrategy(this.itemsManager);
  }

  setStrategy(strategy: 'single' | 'split') {
    if (strategy === 'single') {
      this.strategy = new SinglePaymentStrategy(this.itemsManager);
    }
    if (strategy === 'split') {
      this.strategy = new SplitPaymentStrategy(this.itemsManager);
    }
  }

  addPayment(payment: AddedPayment) {
    this.assignFormattedAmount(payment);

    if (payment.requires_cash && payment.amount < 0) {
      const errorMessage = 'Payment amount cannot be less than 0';
      toast.error(errorMessage);
      console.error(errorMessage);
      return;
    }

    if (
      payment.requires_code &&
      (!validateIntegerInput(payment.extra) ||
        String(payment.extra).length <= 2)
    ) {
      if (payment.payment_method_id !== PAYMENT_METHODS.MANUAL_KNET) {
        const errorMessage = 'Invalid code. Please provide a valid code.';
        toast.error(errorMessage);
        console.error(errorMessage);
        return;
      }
    }

    this.strategy.addPayment(payment);
  }

  removePayment(key: number) {
    this.strategy.removePayment(key);
  }

  clearPayments() {
    // Todo: assigning a new array changes the reference
    // which might cause issues in reactivity in vue
    // check if this is the case
    this.strategy.payments = [];
  }

  hasPayment(paymentId: PAYMENT_METHODS) {
    return this.payments.some(
      (payment) => payment.payment_method_id === paymentId,
    );
  }

  sumPayments(paymentId: PAYMENT_METHODS) {
    return this.payments
      .filter((payment) => payment.payment_method_id === paymentId)
      .reduce((acc, payment) => acc + payment.amount, 0);
  }

  private assignFormattedAmount(payment: IPaymentBase) {
    return {
      ...payment,
      amount: toFloat(payment.amount),
    };
  }
}
