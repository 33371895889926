/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Composables
import {createVuetify} from 'vuetify';
import {createVueI18nAdapter} from 'vuetify/locale/adapters/vue-i18n';
import {useI18n} from 'vue-i18n';
import i18n from './i18n';
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          background: '#ececec',
          secondaryBackground: '#F6E7D7',
          primary: '#e74e0f',
          secondary: '#feede7',
          weakTextColor: '#848484',
          inputColor: '#ADADAD',
          orange: 'ee7d00',
        },
      },
    },
  },
  defaults: {
    VTextField: {
      style: 'border-radius: 8px; ',
      variant: 'solo',
    },
    VBtn: {
      style:
        'border-radius: 7px;box-shadow:none;text-transform: none;overflow:hidden',
    },
    VCard: {
      class: 'elevation-0',
    },
  },
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
});
