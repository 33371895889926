import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { parse, stringify } from 'flatted';
import { PiniaSharedState } from './shared';

export const pinia = createPinia().use(piniaPluginPersistedstate);

pinia.use(
  PiniaSharedState({
    enable: true,
    type: 'idb',
    serializer: {
      serialize: stringify,
      deserialize: parse,
    },
  }),
);
