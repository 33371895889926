import { ref } from 'vue';
import type { HealthStatus } from '../sync-check/use-health-check';
import { BACKEND_STATUS, BackendStatusTopic } from './backend-status-topic';

export const useBackendStatusCheck = async () => {
  const status = ref<HealthStatus>('loading');

  BackendStatusTopic.subscribe('STATUS', (newStatus) => {
    status.value = newStatus === BACKEND_STATUS.ONLINE ? 'ready' : 'error';
  });

  BackendStatusTopic.publish('CHECK');

  return {
    status,
    label: 'Backend Health' as const,
  };
};
