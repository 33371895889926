import { type CachedPaymentMethod, PAYMENT_METHODS } from '@trolley/types';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { usePendingTransactionStore } from '@trolley/transactions/vue';
import { trolleyClient } from '@trolley/api-sdk';
import { paymentMethodAdapter } from '@trolley/utils';
import { KNET_CONNECTION_COMMANDS, knetMachineTopic } from '@trolley/knet';
import { subscribe } from '@enegix/events';
import { TRANSACTION_EVENTS } from '@trolley/transactions';
import { EMPLOYEE_REQUESTS } from '../../features/usecases/employee/events';

const requiresCashPaymentMethodsOnSplit = [
  PAYMENT_METHODS.CASH,
  PAYMENT_METHODS.MANUAL_KNET,
];
export const splitPaymentAdapter = (
  paymentMethod: CachedPaymentMethod,
): CachedPaymentMethod => ({
  ...paymentMethod,
  requires_cash: requiresCashPaymentMethodsOnSplit.includes(paymentMethod.id),
});
export const usePaymentMethods = defineStore('paymentMethods', () => {
  const paymentMethodsRef = ref<CachedPaymentMethod[]>([]);
  const selectedPaymentMethod = ref<CachedPaymentMethod | null>(null);
  const codeReference = ref<number | null>(null);
  const isLoading = ref(true);
  const isSplitMode = ref(false);
  const filteredPaymentMethods = ref<CachedPaymentMethod[]>([]);
  watch(isSplitMode, (isSplitMode) => {
    if (isSplitMode) {
      filteredPaymentMethods.value = paymentMethodsRef.value
        .filter((method) => method.splittable)
        .map(splitPaymentAdapter);
      usePendingTransactionStore().paymentsManager.setStrategy('split');
    } else {
      filteredPaymentMethods.value = paymentMethodsRef.value;
      usePendingTransactionStore().paymentsManager.setStrategy('single');
    }
    selectedPaymentMethod.value = null;
  });
  const fetchPaymentMethods = async () => {
    try {
      const response =
        await trolleyClient.paymentMethods.getApiPosTransactionsPaymentMethods();
      const paymentMethods = response.data as CachedPaymentMethod[];
      paymentMethodsRef.value = paymentMethods.map(paymentMethodAdapter);
      filteredPaymentMethods.value = paymentMethodsRef.value;
    } catch (error) {
      console.error('Error fetching payment methods:', error);
    } finally {
      isLoading.value = false;
    }
    knetMachineTopic.publish('COMMANDS', KNET_CONNECTION_COMMANDS.PING);
  };
  knetMachineTopic.subscribe('STATUS', (status) => {
    const paymentMethod = filteredPaymentMethods.value.find(
      (method) => method.id === PAYMENT_METHODS.AUTO_KNET,
    );
    if (!paymentMethod) return;
    if (status === 'connected' || status === 'pong') {
      paymentMethod.disabled = false;
    } else {
      paymentMethod.disabled = true;
    }
  });
  subscribe(
    [
      TRANSACTION_EVENTS.PAID,
      'checkout-back',
      TRANSACTION_EVENTS.FAILED,
      EMPLOYEE_REQUESTS.STASH_SALE,
      TRANSACTION_EVENTS.VOID,
    ],
    () => {
      selectedPaymentMethod.value = null;
      codeReference.value = null;
      isSplitMode.value = false;
    },
  );
  return {
    paymentMethods: filteredPaymentMethods,
    allPaymentMethods: paymentMethodsRef,
    selectedPaymentMethod,
    codeReference,
    isLoading,
    isSplitMode,
    fetchPaymentMethods,
  };
});
