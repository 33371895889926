import {
  BaseHttpRequest,
  decodeBase64,
  fetchAllPagesMethodFactory,
  UsersService,
} from '@trolley/api-sdk';
import {
  DexieStorageAdapter,
  FetchAndCacheOnce,
  IntervalFetchAndCache,
  StartIntervalFetchAndCacheChangesPatch,
} from '../interval-fetch-and-cache';
import { db } from '@trolley/db';
import { gcm } from '@noble/ciphers/aes';

const usersPageDecrypt = (data: { d: string; n: string; t: string }) => {
  const { d, n, t } = data;
  if (!d || !n || !t) return [];

  const ciphertext = decodeBase64(d).slice(16);
  const key = decodeBase64(d).slice(0, 16);
  const nonce = decodeBase64(n);
  const tag = decodeBase64(t);

  // Concatenate the ciphertext and tag as required by the gcm implementation.
  const combinedCiphertext = new Uint8Array(ciphertext.length + tag.length);
  combinedCiphertext.set(ciphertext);
  combinedCiphertext.set(tag, ciphertext.length);

  const decryptedData = gcm(key, nonce).decrypt(combinedCiphertext);
  const text = new TextDecoder().decode(decryptedData);
  return JSON.parse(text);
};
const usersAdapter = (data: []) => data.map(usersPageDecrypt).flat();

export class OfflineUsersService extends UsersService {
  private cachedItems: IntervalFetchAndCache<any>;

  constructor(request: BaseHttpRequest) {
    super(request);
    this.cachedItems = FetchAndCacheOnce.create(
      new DexieStorageAdapter(db.users),
      fetchAllPagesMethodFactory(
        super.getApiPosSyncAllUsers.bind(this),
        (data) => data.d === '',
      ),
      usersAdapter,
    );

    new StartIntervalFetchAndCacheChangesPatch(
      new DexieStorageAdapter(db.users),
      super.getApiPosSyncUsers.bind(this),
      usersPageDecrypt,
    );
  }

  override getApiPosSyncAllUsers() {
    return this.cachedItems.getData();
  }
}
