import { createI18n } from "vue-i18n";
import { en, ar } from "vuetify/locale";
import arabic from "../locales/ar.json";
import english from "../locales/en.json";
if (!localStorage.getItem("lang")) {
  localStorage.setItem("lang", "en");
}
const messages = {
  en: {
    $vuetify: {
      ...en,
      dataIterator: {
        rowsPerPageText: "Items per page:",
        pageText: "{0}-{1} of {2}",
      },
    },
    ...english,
  },
  ar: {
    $vuetify: {
      ...ar,
      dataIterator: {
        rowsPerPageText: "عدد العناصر في الصفحة",
      },
    },
    ...arabic,
  },
};
export default createI18n({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
  fallbackLocale: "en",
  messages,
});
