import { type BaseHttpRequest, VoidReasonsService } from '@trolley/api-sdk';
import {
  DexieStorageAdapter,
  FetchAndCacheOnce,
  IntervalFetchAndCache,
  StartIntervalFetchAndCacheChangesPut,
} from '../interval-fetch-and-cache';
import { IVoidReason } from '@trolley/types';

import { db } from '@trolley/db';

export class OfflineVoidReasonsService extends VoidReasonsService {
  private offlineService: IntervalFetchAndCache<{ data: IVoidReason[] }>;

  constructor(request: BaseHttpRequest) {
    super(request);
    this.offlineService = FetchAndCacheOnce.create(
      new DexieStorageAdapter(db.voidReasons),
      () =>
        super.getApiPosSyncVoidReasons.call(this, {
          all: 1,
        }),
    );

    new StartIntervalFetchAndCacheChangesPut(
      new DexieStorageAdapter(db.voidReasons),
      super.getApiPosSyncVoidReasons.bind(this),
    );
  }

  override getApiPosVoidReasons() {
    return this.offlineService.getData();
  }
}
