<template>
  <v-app>
    <v-container class="h-screen d-flex flex-column justify-center align-center max-w-md text-center">
      <v-card elevation="4" class="pa-6">
        <v-card-title>
          <span>Trolley POS cashier screen is already open in another tab!</span>
        </v-card-title>

        <v-card-text>
          <p>
            Please close this tab and return to the cashier screen which is already open.
          </p>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
export default {};
</script>
