import { injectAccessToken } from './api';
import { auth, type Auth } from '../modules/auth';
import { removeCookie } from '../composables/cookies';
import { publish } from '@enegix/events';
import { AUTH_EVENTS } from '../modules/auth/events';
import router from '../router';
import { VITE_POS_SYNC_KEY } from '../env';
import { generateSyncKey, trolleyClient } from '@trolley/api-sdk';

async function login(username, password) {
  const loginResponse = (await trolleyClient.default.postApiPosLogin({
    formData: {
      username,
      password,
    },
  })) as unknown as Auth;
  auth()._store.$patch({ auth: loginResponse });
  await injectAccessToken(loginResponse.token);
  publish(AUTH_EVENTS.LOG_IN, loginResponse);
}

async function startSession(cash_in = 0) {
  const response = await trolleyClient.default.postApiPosCashIn({
    formData: {
      cash_in,
    },
  });
  auth().state.sessionStarted.value = true;
  publish(AUTH_EVENTS.SESSION_START, response);
}

async function checkOrNot() {
  const response = await trolleyClient.default.getApiPosCheckOrNot();
  auth().state.endOfDay.value = response.success;
}

async function logout(cash_out = 0, end_knet = 0, type = 1) {
  await trolleyClient.default.postApiPosLogout({
    formData: {
      cash_out,
      end_knet,
      type,
    },
  });
  await revokeToken();
  // reload to reset subscribers and state
  // router.go(0);
}

async function revokeToken() {
  await removeCookie('token');
  publish(AUTH_EVENTS.LOG_OUT);
  await router.push({
    name: 'login',
    params: { terminal_id: localStorage.getItem('tid') },
  });
}

export const syncKey = (() => {
  const terminalId = localStorage.getItem('tid');
  const posSyncKey = VITE_POS_SYNC_KEY;
  if (!terminalId || !posSyncKey) {
    console.error(
      `Terminal ID or POS Sync Key not found,
      terminalId: ${terminalId}, posSyncKey: ${posSyncKey}`,
    );
    return;
  }

  return generateSyncKey({ terminalId, posSyncKey });
})();

export { login, logout, revokeToken, checkOrNot, startSession };
