import { Transaction } from './transaction';
import { publish } from '@enegix/events';

export abstract class ActiveTransaction extends Transaction {
  coupon?: string;
  isSubmitting = false;
  isRefund = false;
  employee_id?: string;

  protected startSubmitting() {
    this.itemsManager.lock();
    this.isSubmitting = true;
    publish('FREEZE_UI');
  }

  protected stopSubmitting() {
    this.isSubmitting = false;
    publish('UNFREEZE_UI');
  }

  clear() {
    this.itemsManager.clear();
    this.paymentsManager.clearPayments();
  }
}
