import { toast } from 'vue3-toastify';
import { publish } from '@enegix/events';
import { trolleyClient } from '@trolley/api-sdk';
import {
  ITransactionResponse,
  PAYMENT_METHODS,
  TRANSACTION_STATUS,
} from '@trolley/types';
import { ActiveTransaction } from './active-transaction';
import { TRANSACTION_EVENTS } from '../topic/events';
import { CompletedTransaction } from './completed-transaction';
import { AutoKnetTransactionHandler } from './auto-knet-transaction-handler';
import { StashedTransaction, StashManager } from './stashed-transaction';
import { CouponManager } from './coupon-manager';

export class PendingTransaction extends ActiveTransaction {
  couponManager = new CouponManager(this);
  private static instance: PendingTransaction | null = null;

  static getInstance() {
    if (!PendingTransaction.instance) {
      PendingTransaction.instance = new PendingTransaction({
        items: [],
        payments: [],
        sub_total: 0,
        amount: 0,
        redeemed_amount: 0,
        redeem_amount_points: 0,
      });
    }
    return PendingTransaction.instance;
  }

  suspend() {
    StashManager.addStash(new StashedTransaction(this));
    this.clear();
  }

  resume(stashId: number) {
    if (this.itemsManager.items.length) return;

    const stash = StashManager.getStash(stashId);
    if (!stash) {
      toast.error('Invalid stash ID');
      return;
    }
    const items = stash.itemsManager.strategy.items.reverse();
    this.itemsManager.fancyBulkAddItems(items).then(() => {
      StashManager.removeStash(stashId);
    });
  }

  async submit(extraInfo: any = {}) {
    if (!this.paymentsManager.isSubmittable || this.isSubmitting) return;
    this.startSubmitting();
    publish(TRANSACTION_EVENTS.CREATING);
    try {
      const response = await this.createTransaction(extraInfo);
      const orderData = response['data'] as ITransactionResponse;
      const completedTransaction = new CompletedTransaction(orderData);
      await this.handleCompletedTransaction(completedTransaction);
    } catch (error) {
      this.handleError(error, true, extraInfo);
    }
  }

  override clear() {
    super.clear();
    this.couponManager.clearCoupon();
  }

  async voidTransaction(void_reason_id: number) {
    this.startSubmitting();
    try {
      await this.createTransaction({
        readable_status: 'voided',
        status: TRANSACTION_STATUS.VOIDED,
        void_reason_id,
      });
      this.clear();
    } catch (error) {
      this.handleError(error);
    } finally {
      this.stopSubmitting();
    }
  }

  private async handleCompletedTransaction(
    completedTransaction: CompletedTransaction,
  ) {
    publish(TRANSACTION_EVENTS.CREATED, completedTransaction);
    if (completedTransaction.isPaid) {
      this.finalizeTransaction(completedTransaction);
    } else if (this.paymentsManager.hasPayment(PAYMENT_METHODS.AUTO_KNET)) {
      AutoKnetTransactionHandler.processKnetPayment(completedTransaction)
        .then(() => {
          this.finalizeTransaction(completedTransaction);
        })
        .catch((e) => {
          this.handleError(e, false);
          completedTransaction.printReceipt();
        })
        .finally(() => {
          this.stopSubmitting();
        });
    }
  }

  private finalizeTransaction(transaction: CompletedTransaction) {
    publish(TRANSACTION_EVENTS.PAID, transaction);
    transaction.printReceipt();
    this.clear();
    this.stopSubmitting();
  }

  private createTransaction(
    extraInfo: Partial<{ redeem_amount: number } & any> = {},
  ) {
    return trolleyClient.orders.postApiPosTransactionsStore({
      requestBody: {
        items: this.items,
        payments: this.payments,
        coupon: this.coupon,
        amount: this.itemsManager.totalDue,
        sub_total: this.itemsManager.subTotal,

        type_from: this.type_from,
        employee_id: this.employee_id,

        // redeem
        redeem_amount: this.itemsManager.redeemedAmount,
        redeem_amount_points: this.itemsManager.totalEarnedPoints,

        // needed in offline
        discount_value: this.itemsManager.totalDiscount,
        ...extraInfo,
      },
    });
  }

  private handleError(error: any, showErrorMessage = true, extraInfo = {}) {
    this.paymentsManager.clearPayments();
    console.error(error);
    publish(TRANSACTION_EVENTS.FAILED);
    if (!showErrorMessage) return;
    console.error(this);

    if (error.message === 'Network Error') {
      if ('redeem_amount' in extraInfo && extraInfo.redeem_amount) {
        toast.error(
          "Couldn't reach the server, please remove the redeemed amount and try again",
        );
      }
      if (this.coupon) {
        this.couponManager.clearCoupon();
        toast.error(
          "Couldn't reach the server, coupon has been removed, please try again",
        );
      }
    } else {
      toast.error(error.message);
    }

    this.stopSubmitting();
  }
}
