import {
  initAppMode,
  setupAppModeWatchers,
} from './app-mode/backend-status-topic';
import { startInterval, trolleyClient } from '@trolley/api-sdk';
import { versionUpdateFeature } from '../modules/settings/version-update';
// works only in cart page
export default {
  bootstrap() {
    const preAuthSystemFeatures = [
      {
        name: 'App Mode',
        description: 'Detect if the app is offline or online',
        enabled: true,
        handlers: [initAppMode, setupAppModeWatchers],
        registered: false,
      },
      {
        name: 'Sync Offline Transactions',
        description: 'Sync offline transactions with the server',
        enabled: true,
        handlers: [
          // try to sync once on app start
          () =>
            trolleyClient.transactions.postApiPosSyncTransactions.apply(
              trolleyClient.transactions,
            ),
          // start a sync interval
          () =>
            startInterval(() =>
              trolleyClient.transactions.postApiPosSyncTransactions.apply(
                trolleyClient.transactions,
              ),
            ),
        ],
        registered: false,
      },
      {
        name: 'App Version Update',
        description: 'Check if the app needs to be updated',
        enabled: true,
        handlers: [versionUpdateFeature],
        registered: false,
      },
    ];

    preAuthSystemFeatures.map((feature) => {
      if (feature.enabled && !feature.registered)
        feature.handlers.map((handler) => {
          handler();
          feature.registered = true;
        });
    });
  },
};
