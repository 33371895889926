import { ref } from 'vue';
import type { HealthStatus } from '../sync-check/use-health-check';
import { z } from 'zod';
import { getCachedEntity, trolleyClient } from '@trolley/api-sdk';

export const userSchema = z.object({
  id: z.number(),
  username: z.string(),
  warehouse_id: z.number(),
  name: z.string(),
  password: z.string(),
});

export const useUsersCheck = async () => {
  const status = ref<HealthStatus>('loading');
  const count = ref(0);

  const users = await getCachedEntity('users');

  if (!users.length)
    await trolleyClient.users.getApiPosSyncAllUsers.bind(trolleyClient.users)();

  const usersHealth = Promise.all(
    users.map((user) => userSchema.safeParseAsync(user)),
  );

  const results = await usersHealth;

  status.value = results.every((result) => {
    if (!result.success) {
      console.error(result.error);
    }
    return result.success;
  })
    ? 'ready'
    : 'error';
  count.value = results.length;

  return {
    status,
    label: 'Users' as const,
    count,
  };
};
