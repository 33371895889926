import { ItemsStrategy } from './types';
import { Item } from '../item';
import { IItem } from '@trolley/types';

export class SaleItemsStrategy implements ItemsStrategy {
  items: Item[] = [];

  addItem(
    targetItem: IItem,
    targetUPC?: string,
    method: 'push' | 'unshift' = 'unshift',
  ) {
    const item = new Item(targetItem, targetUPC);
    if (this.hasItem(item.id))
      this.increaseQuantity(item.id, item.quantity, targetUPC);
    // in sale we unshift the items, in refund we push them according to the ui
    else this.items[method](item);
  }

  removeItem(id: number) {
    const item = this.getItem(id);
    if (!item) return;

    item.isEditable = false;
    this.items.splice(this.items.indexOf(item), 1);
  }

  increaseQuantity(id: number, amount = 1, scannedUPC?: string) {
    const item = this.getItem(id);
    if (!item) return;
    const newQuantity = scannedUPC
      ? item?.barcodes[scannedUPC].quantity
      : amount;
    item.quantity += newQuantity;
  }

  decreaseQuantity(id: number) {
    const item = this.getItem(id);
    if (!item) return;
    if (item.quantity > 0) item.quantity--;
    if (item.quantity === 0) this.removeItem(id);
  }

  getItem(id: number) {
    return this.items.find((item) => item.id === id);
  }

  hasItem(id: number) {
    return this.items.some((item) => item.id === id);
  }
}
