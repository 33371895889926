<template>
  <v-dialog
    @close="onClose"
    @click:outside="onClose"
    id="suspendDialog"
    :width="dialogWidth"
    :height="dialogHeight"
    :persistent="persistent"
  >
    <v-card
      id="suspendCard"
      :style="cardStyle"
      style="overflow: visible; border-radius: 10px"
    >
      <template #title>
        <div
          :style="headerStyle"
          class="bg-primary d-flex justify-center align-center cardTitle"
        >
          <template v-if="headerText">
            <p class="text-center" style="font-size: 20px; font-weight: bold">
              {{ headerText }}
            </p>
          </template>
          <template v-else>
            <img
              v-if="img"
              :src="img"
              style="position: absolute; bottom: 0; width: 120px"
            />
            <dialogHeader v-else style="position: absolute; bottom: 0" />
          </template>
          <div v-if="onClose">
            <v-btn
              variant="text"
              icon="mdi mdi-close-circle"
              style="position: absolute; top: 0; right: 0"
              @click="onClose"
            />
          </div>
        </div>
      </template>
      <template v-if="title || text" #text>
        <div
          :style="{ backgroundColor: contentBgColor }"
          class="pt-6 dialog-contents"
        >
          <p
            v-if="title"
            class="text-primary text-center mb-2"
            style="font-size: 20px; font-weight: bold"
          >
            {{ title }}
          </p>
          <p
            v-if="text"
            style="font-size: 16px; font-weight: normal"
            class="text-weakTextColor text-center"
          >
            {{ text }}
          </p>
        </div>
      </template>
      <template #default>
        <div :style="{ backgroundColor: contentBgColor }" class="pa-6 pt-0">
          <slot></slot>
        </div>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import dialogHeader from '../../assets/icons/dialogHeader.svg';
import { computed } from 'vue';

const props = defineProps<{
  title?: string;
  headerText?: string;
  text?: string;
  size?: string;
  contentBgColor?: string;
  onClose?: () => void;
  persistent?: boolean;
  img?: string;
}>();

const dialogWidth = computed(() => (props.size === 'large' ? '90vw' : 'auto'));
const dialogHeight = computed(() => (props.size === 'large' ? '90vh' : 'auto'));
const cardStyle = computed(() =>
  props.size === 'large' ? 'max-width: none; max-height: none;' : '',
);
const contentBgColor = computed(() => props.contentBgColor || '#ffffff');
const headerStyle = computed(() => ({
  minHeight: '100px',
  width: '100%',
  position: 'relative',
  borderRadius: '10px 10px 0 0',
  height: props.size === 'large' ? 'auto' : '100px',
}));

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    props.onClose?.();
  }
};

// onMounted(() => {
//   window.addEventListener('keydown', handleKeydown);
// });
//
// onUnmounted(() => {
//   window.removeEventListener('keydown', handleKeydown);
// });
</script>

<style scoped>
.dialog-contents {
  max-width: 650px;
  margin: auto;
}

.text-primary {
  color: #ffffff;
}

.text-weakTextColor {
  color: #757575;
}

.cardTitle {
  height: 60px !important;
  min-height: 60px !important;
}
</style>
