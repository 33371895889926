// axios.js
import axios from 'axios';
import { VITE_APP_BASE_URL } from '../env';

const axiosInstance = axios.create({
  baseURL: VITE_APP_BASE_URL,
  headers: {
    'Content-type': 'application/json',
    'X-Pos-Rk': 'remote_key',
    'Terminal-Id': '12700018',
  },
  // Add other configuration options if necessary
});

export default axiosInstance;
