import { defineStore } from 'pinia';
import { ITransactionVisitor, PendingTransaction } from '@trolley/transactions';
import { toFloat } from '@trolley/utils';
import { parse, stringify } from 'flatted';
// @ts-expect-error - This is a workaround for the missing type definition
export const usePendingTransactionStore = defineStore(
  'usePendingTransactionStore',
  {
    state: () => PendingTransaction.getInstance() as PendingTransaction,
    persist: {
      pick: ['itemsManager'],
      serializer: {
        serialize: stringify,
        deserialize: parse,
      },
    },
    share: {
      // TODO: change to paths include
      readonlyFromPaths: ['/client-home'],
    },
    getters: {
      isProcessable(state) {
        return state.itemsManager.totalItems > 0;
      },
      isSubmittable(state) {
        return state.paymentsManager.isSubmittable;
      },
      items(state) {
        return state.itemsManager.items;
      },
      payments(state) {
        return state.paymentsManager.payments;
      },
      // add discount
      remainingAmount(state) {
        return toFloat(
          state.itemsManager.totalDue - state.paymentsManager.totalPaidAmount,
        );
      },
    },
    actions: {
      submit(extraInfo?: any) {
        return this.$state.submit(extraInfo);
      },
      suspend() {
        this.$state.suspend();
      },
      resume(stashId: number) {
        this.$state.resume(stashId);
      },
      voidTransaction(void_reason_id: number) {
        return this.$state.voidTransaction(void_reason_id);
      },
      accept(visitor: ITransactionVisitor) {
        this.$state.accept(visitor);
      },
    },
  },
);
