import { toast } from 'vue3-toastify';
import {
  offlineClientTopic,
  onlineTrolleyClient,
  trolleyClient,
} from '@trolley/api-sdk';
import { TERMINAL_ID, VITE_APP_BASE_URL } from '../env';
import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios';
import { revokeToken, syncKey } from './auth';
import { getCookie, setCookie } from '../composables/cookies';
import { publish } from '@enegix/events';

let isInjected = false;
export const injectSDKConfig = () => {
  if (isInjected) return;
  Object.assign(onlineTrolleyClient.request.config, {
    BASE: VITE_APP_BASE_URL,
    HEADERS: {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json',
    },
  });
  onlineTrolleyClient.request.config.interceptors.response.use(errorHandler);
  onlineTrolleyClient.request.config.interceptors.response.use(successHandler);
  onlineTrolleyClient.request.config.interceptors.request.use(attachHeaders);
  offlineClientTopic.subscribe('SHOW_ERROR', toast.error);
  offlineClientTopic.subscribe('SESSION_NOT_FOUND', () => {
    toast.error('Local Session not found');
    revokeToken();
  });
  offlineClientTopic.subscribe('NEW_SERVER_TOKEN', async (token) => {
    await injectAccessToken(token);
  });
  const cachedToken = getCookie('token');
  if (cachedToken) injectAccessToken(cachedToken);
  isInjected = true;
};

const ignoredEndpoints = ['current-session', 'cash-in'];
let failedQueue: Array<{
  config: AxiosRequestConfig;
  resolve: Function;
  reject: Function;
}> = [];

async function errorHandler(response: AxiosResponse) {
  const requestUrl = response.config.url || '';
  if (ignoredEndpoints.some((endpoint) => requestUrl.includes(endpoint))) {
    return response;
  }

  // validation error, show toast with array of errors
  if (response.status === 422) {
    const errors = response.data.errors;
    if (errors) {
      errors.forEach((error) => toast.error(error));
    }
  } else if (response.status !== 200) {
    toast.error(response.data.message);
  }

  if (response.status >= 500) {
    publish('backend-error', {});
  }

  const authHeader = (response.config.headers?.Authorization || '') as string;
  const isOfflineToken = authHeader.includes('offline');

  if (response.status === 401) {
    const ignoredPaths = ['login', 'logout'];
    const isIgnored = ignoredPaths.some((path) => requestUrl.includes(path));
    if (isIgnored) return response;

    if (!isOfflineToken) await revokeToken();
    else if (isOfflineToken) {
      new Promise((resolve, reject) => {
        failedQueue.push({ config: response.config, resolve, reject });
      });

      await toast.promise(
        trolleyClient.transactions.postApiPosSyncTransactions(),
        {
          pending: 'Back to online mode',
          success: 'We are back online, try again!',
          error: 'Error occurred while handling offline token',
        },
      );

      // update the token in the request config of all failed requests
      failedQueue.forEach(({ config }) => {
        if (!config.headers) config.headers = {};
        config.headers.Authorization = `Bearer ${onlineTrolleyClient.request.config.TOKEN}`;
      });

      try {
        failedQueue.forEach(({ config, resolve }) => {
          resolve(axios(config));
        });
        failedQueue = [];
      } catch (err) {
        failedQueue.forEach(({ reject }) => reject(err));
        failedQueue = [];
        throw err;
      }
    }
  }

  return response;
}

async function successHandler(response: AxiosResponse) {
  if (response.status === 201) toast.success('Created successfully');
  if (response.status === 204) toast.success('Deleted successfully');

  return response;
}

async function attachHeaders(request: AxiosRequestConfig) {
  console.log({ TERMINAL_ID });
  return {
    ...request,
    headers: {
      ...request.headers,
      'Terminal-Id': TERMINAL_ID,
      'X-Pos-Rk': 'remote_key',
      'X-Pos-Sk': syncKey,
    },
  };
}

export const injectAccessToken = async (token: string) => {
  await setCookie('token', token, 30);
  assignTokenToSDK(token);
  // TODO: this might be a mistake, as we are accessing the store before it's initialized
  // TODO: inject token to store, it's not used anywhere but it's a good practice
  // useAuthStore().$patch({ auth: { token } });
};

const assignTokenToSDK = (token: string) => {
  onlineTrolleyClient.request.config.TOKEN = token;
};
