import { type BaseHttpRequest, QuickListService } from '@trolley/api-sdk';
import {
  DexieStorageAdapter,
  FetchAndCacheOnce,
  IntervalFetchAndCache,
  StartIntervalFetchAndCacheChangesPut,
} from '../interval-fetch-and-cache';

import { db } from '@trolley/db';

export class OfflineQuickListService extends QuickListService {
  private offlineGetQuickList: IntervalFetchAndCache<Record<string, unknown>>;

  constructor(request: BaseHttpRequest) {
    super(request);
    // @ts-expect-error - no data type
    this.offlineGetQuickList = FetchAndCacheOnce.create(
      new DexieStorageAdapter(db.quickList),
      () =>
        super.getApiPosSyncQuickList.call(this, {
          all: 1,
        }),
    );

    new StartIntervalFetchAndCacheChangesPut(
      new DexieStorageAdapter(db.quickList),
      super.getApiPosSyncQuickList.bind(this),
    );
  }

  override getApiPosQuickListCategories() {
    return this.offlineGetQuickList.getData();
  }
}
