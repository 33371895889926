import {
  DefaultService,
  DiscountsService,
  ItemsService,
  OnlineTrolleyClient,
  OrdersService,
  PaymentMethodsService,
  QuickListService,
  RefundsService,
  SettingsService,
  TransactionsService,
  UsersService,
  VoidReasonsService,
} from './online-client';
import { OfflineDefaultService } from './offline-client/offline-services/default';
import { OfflineItemsService } from './offline-client/offline-services/items';
import { OfflinePaymentMethodsService } from './offline-client/offline-services/payment-methods';
import { OfflineVoidReasonsService } from './offline-client/offline-services/void-reasons';
import { OfflineQuickListService } from './offline-client/offline-services/quick-list';
import { OfflineOrdersService } from './offline-client/offline-services/orders';
import { OfflineTransactionsService } from './offline-client/offline-services/transactions';
import { OfflineUsersService } from './offline-client/offline-services/users';
import { OfflineSettingsService } from './offline-client/offline-services/settings';

// TODO: refactor this by allowing db to be injected, so we can use it in the tests
export class TrolleyClient {
  private readonly onlineClient: OnlineTrolleyClient;

  public readonly default: DefaultService;
  public readonly discounts: DiscountsService;
  public readonly orders: OrdersService;
  public readonly quickList: QuickListService;
  public readonly refunds: RefundsService;
  public readonly transactions: TransactionsService;
  public readonly voidReasons: VoidReasonsService;
  public readonly settings: SettingsService;
  public readonly items: ItemsService;
  public readonly paymentMethods: PaymentMethodsService;
  public readonly users: UsersService;

  constructor({ onlineClient }: { onlineClient: OnlineTrolleyClient }) {
    this.onlineClient = onlineClient;
    this.voidReasons = new OfflineVoidReasonsService(this.onlineClient.request);
    this.quickList = new OfflineQuickListService(this.onlineClient.request);
    this.orders = new OfflineOrdersService(this.onlineClient.request);
    this.default = new OfflineDefaultService(this.onlineClient.request);

    this.discounts = new DiscountsService(this.onlineClient.request);
    this.refunds = new RefundsService(this.onlineClient.request);

    this.settings = new OfflineSettingsService(this.onlineClient.request);
    this.items = new OfflineItemsService(this.onlineClient.request);
    this.paymentMethods = new OfflinePaymentMethodsService(
      this.onlineClient.request,
    );

    this.transactions = new OfflineTransactionsService(
      this.onlineClient.request,
    );

    this.users = new OfflineUsersService(this.onlineClient.request);
  }
}

export const onlineTrolleyClient = new OnlineTrolleyClient();
export const trolleyClient = new TrolleyClient({
  onlineClient: onlineTrolleyClient,
});
