import { defineStore } from 'pinia';
import { ref } from 'vue';
import { trolleyClient } from '@trolley/api-sdk';

export const itemsStore = defineStore(
  'ItemsStore',
  () => {
    const allItems = ref([]);

    const fetchAllItems = async () => {
      const { data } = await trolleyClient.items.getApiPosSyncAllItems();
      allItems.value = data;
    };

    return {
      allItems,
      fetchAllItems,
    };
  },
  {
    share: { enable: false },
  },
);
