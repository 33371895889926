import {
  type BaseHttpRequest,
  CancelablePromise,
  fetchAllPagesMethodFactory,
  ItemsService,
  offlineClientTopic,
} from '@trolley/api-sdk';
import { IProduct } from '@trolley/types';
import {
  DexieStorageAdapter,
  FetchAndCacheOnce,
  IntervalFetchAndCache,
  StartIntervalFetchAndCacheChangesPatch,
} from '../interval-fetch-and-cache';

import { db } from '@trolley/db';

const itemsAdapter = (items: IProduct[]) =>
  items.map((item) => ({
    ...item,
    barcodeIds: Object.keys(item.barcodes),
  }));

export class OfflineItemsService extends ItemsService {
  private cachedItems: IntervalFetchAndCache<any>;

  constructor(request: BaseHttpRequest) {
    super(request);
    this.cachedItems = FetchAndCacheOnce.create(
      new DexieStorageAdapter(db.items),
      fetchAllPagesMethodFactory(super.getApiPosSyncAllItems.bind(this)),
      itemsAdapter,
    );

    new StartIntervalFetchAndCacheChangesPatch(
      new DexieStorageAdapter(db.items),
      super.getApiPosSyncItems.bind(this),
      itemsAdapter,
    );
  }

  override getApiPosSyncAllItems() {
    return this.cachedItems.getData();
  }

  override getApiPosItemById(data: { id: string }) {
    return new CancelablePromise<Record<string, unknown>>((resolve, reject) => {
      db.items
        .where('barcodeIds')
        .equals(data.id)
        .first()
        .then((item) => {
          if (item) resolve({ data: item });
          else
            offlineClientTopic.publish(
              'SHOW_ERROR',
              `Could NOT find item with barcode ${data.id}`,
            );
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
