import { AddedPayment } from '@trolley/types';
import { Payment } from '@trolley/payments';
import { ItemsManager } from '@trolley/items';
import { PaymentStrategy } from './types';
import { toast } from 'vue3-toastify';
import { almostEqual, toFloat } from '@trolley/utils';

export class SplitPaymentStrategy implements PaymentStrategy {
  payments: Payment[] = [];
  get totalPaidAmount() {
    return this.payments.reduce((acc, payment) => acc + payment.amount, 0);
  }
  get isSubmittable() {
    return almostEqual(this.totalPaidAmount, this.itemsManager.totalDue);
  }
  constructor(private itemsManager: ItemsManager) {}

  addPayment(payment: AddedPayment) {
    if (toFloat(payment.amount) > toFloat(this.itemsManager.totalDue)) {
      toast.error('Payment amount exceeds total due');
      return;
    }

    if (!payment.splittable) {
      toast.error('Payment method is not splittable');
      return;
    }
    if (payment.repeatable) this.payments.push(new Payment(payment));
    else {
      const prevPayment = this.getPaymentByPaymentMethodId(
        payment.payment_method_id,
      );
      if (prevPayment) this.removePayment(prevPayment.key);
      this.payments.push(new Payment(payment));
    }
  }

  removePayment(key: number) {
    this.payments.splice(
      this.payments.findIndex((payment) => payment.key === key),
      1,
    );
  }

  getPaymentByPaymentMethodId(payment_method_id: number) {
    return this.payments.find(
      (payment) => payment.payment_method_id === payment_method_id,
    );
  }
}
