/// <reference types="vite-plugin-pwa/vue" />
import { z } from 'zod';
import { removeAllSyncHealthChecks } from './utils/sync';
import { toast } from 'vue3-toastify';

export const VITE_APP_BASE_URL = z
  .string()
  .parse(import.meta.env.VITE_APP_BASE_URL);

export const VITE_POS_SYNC_KEY = z
  .string()
  .parse(import.meta.env.VITE_POS_SYNC_KEY);

export const LAST_COMMIT_HASH = z
  .string()
  .parse(import.meta.env.LAST_COMMIT_HASH);

export const TERMINAL_ID = (() => {
  const tid = localStorage.getItem('tid');
  const url = new URL(window.location.href);
  const tidSearchParam = url.searchParams.get('tid');

  if (tidSearchParam && tid != tidSearchParam) {
    toast(`Terminal ID changed to ${tidSearchParam}`);
    localStorage.setItem('tid', tidSearchParam);
    removeAllSyncHealthChecks();
  }

  return tidSearchParam || tid;
})();

export const VITE_HONEYBADGER_KEY = z
  .string()
  .parse(import.meta.env.VITE_HONEYBADGER_KEY);

export const ENVIRONMENT = (() => {
  const isStaging = z.string().safeParse(import.meta.env.VITE_IS_STAGING);

  // TODO: if staging env is set 0, then it will still be true.
  // fix it
  if (isStaging.data) return 'staging';
  else return import.meta.env.MODE;
})();

export const VITE_SENTRY_DSN = z
  .string()
  .parse(import.meta.env.VITE_SENTRY_DSN);
