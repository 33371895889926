import {
  FetchAndCacheOnce,
  IntervalFetchAndCache,
  LocalStorageAdapter,
  StartIntervalFetchAndCacheChangesPut,
} from '../interval-fetch-and-cache';
import { BaseHttpRequest, SettingsService } from '@trolley/api-sdk';
import { ISettings } from '@trolley/types';

export class OfflineSettingsService extends SettingsService {
  private offlineGetSettings: IntervalFetchAndCache<ISettings>;

  constructor(request: BaseHttpRequest) {
    super(request);
    // @ts-expect-error - no data type
    this.offlineGetSettings = FetchAndCacheOnce.create(
      new LocalStorageAdapter('settings'),
      super.getApiPosSyncSettings.bind(this),
    );

    new StartIntervalFetchAndCacheChangesPut(
      new LocalStorageAdapter('settings'),
      super.getApiPosSyncSettings.bind(this),
    );
  }

  override getApiPosSyncSettings() {
    return this.offlineGetSettings.getData();
  }
}
