// eslint-disable-next-line @nx/enforce-module-boundaries
import { Topic } from '@enegix/events';
import { KnetResponse } from './types';

export enum KNET_CONNECTION_STATE {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  CONNECTING = 'connecting',
  ERROR = 'error',
  PONG = 'pong',
}

export enum KNET_CONNECTION_COMMANDS {
  RECONNECT = 'reconnect',
  DISCONNECT = 'disconnect',
  PING = 'ping',
}

type KnetTopic = {
  CONNECTED: {
    message: string;
  };
  SUCCESS: KnetResponse;
  DECLINED: KnetResponse;
  RESPONSE: KnetResponse;
  ERROR: {
    message: string;
  };
  STATUS: KNET_CONNECTION_STATE;
  COMMANDS: KNET_CONNECTION_COMMANDS;
};

export const knetMachineTopic = new Topic<KnetTopic>();
