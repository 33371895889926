export function validateIntegerInput(input: any): boolean {
  if (typeof input !== 'string' && typeof input !== 'number') {
    return false;
  }
  const inputStr = input.toString();
  return /^\d+$/.test(inputStr);
}

export function validateNumberInput(input: any): boolean {
  if (typeof input !== 'string' && typeof input !== 'number') {
    return false;
  }
  const inputStr = input.toString();
  return /^-?\d+(\.\d+)?$/.test(inputStr);
}

export function validatePhone(value: string | number): boolean {
  // if not number return false
  if (!validateIntegerInput(value)) {
    return false;
  }

  // exactly 8 digits
  return /^\d{8}$/.test(value.toString());
}
