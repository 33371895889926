// Composables
import { createRouter, createWebHistory } from 'vue-router';
import { getCookie } from '../composables/cookies';
import { auth } from '../modules/auth';
import { appVersion } from '../modules/settings/store';

// if path already has version, remove it and add the current version
const toVersionedPath = (path) => {
  if (path.startsWith(`/${appVersion.value}/`)) return path;
  return appVersion.value ? `/${appVersion.value}${path}` : `${path}`;
};

const removeOldVersion = (path) => {
  return path.replace(/\/v-\d+\//, '/');
};

const routes = [
  {
    path: '/:appVersion?',
    name: 'EmployeeLayout',
    meta: { requiresAuth: true },
    component: () => import('../layouts/employeeView.vue'),
    children: [
      {
        path: 'cart',
        name: 'cartPage',
        meta: { requiresAuth: true },
        components: {
          default: () => import('../pages/cartPage.vue'),
          aside: () => import('../views/actionPanels/cart/mainCartPanel.vue'),
        },
      },
      {
        path: 'stashed',
        name: 'stashedPage',
        meta: { requiresAuth: true },
        components: {
          default: () => import('../pages/stashedCart.vue'),
          aside: () => import('../views/actionPanels/stashedCartsPanel.vue'),
        },
      },
      {
        path: 'trans',
        name: 'transactionPage',
        meta: { requiresAuth: true },
        components: {
          default: () => import('../pages/transactionPage.vue'),
          aside: () => import('../views/actionPanels/transactionsPanel.vue'),
        },
      },
      {
        path: 'refund',
        name: 'refundPage',
        meta: { requiresAuth: true },
        components: {
          default: () => import('../pages/refundPage.vue'),
          aside: () => import('../views/actionPanels/refundPanel.vue'),
        },
      },
      {
        path: 'receipt',
        name: 'receiptPage',
        components: {
          default: () => import('../features/printing/order/order-receipt.vue'),
        },
      },
      {
        path: 'refund-receipt',
        name: 'refundReceiptPage',
        components: {
          default: () =>
            import('../features/printing/order/refund-receipt.vue'),
        },
      },
    ],
  },
  {
    path: '/:appVersion?',
    name: 'ClientLayout',
    meta: { requiresAuth: false, isClient: true },
    component: () => import('../layouts/clientView.vue'),
    children: [
      {
        path: 'client-home',
        name: 'clientHome',
        meta: { requiresAuth: false, isClient: true },
        components: {
          default: () => import('../pages/clientHomePage.vue'),
          aside: () => import('../views/clientActionPanel.vue'),
        },
      },
    ],
  },
  {
    path: '/:appVersion?',
    name: 'EmptyLayout',
    component: () => import('../layouts/emptyView.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('../pages/loginPage.vue'),
      },
      {
        path: 'test',
        name: 'test',
        component: () => import('../pages/testPage.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const firstPage = toVersionedPath('/cart');

router.beforeEach(async (to, from, next) => {
  try {
    const token = await getCookie('token');
    const sessionStarted = auth().state.sessionStarted;

    console.log({
      appVersion: appVersion.value,
      toAppVersion: to.params.appVersion,
      toPath: to.path,
      toName: to.name,
    });

    if (to.params.appVersion !== appVersion.value) {
      const cleanedPath = removeOldVersion(to.path);
      const newPath = toVersionedPath(cleanedPath);

      // avoid loop when injecting wrong version in link
      if (newPath !== to.path) {
        return next(newPath);
      }
    }

    if (to.meta.requiresAuth && (!token || !sessionStarted)) {
      next(toVersionedPath('/login'));
    } else if (to.path === toVersionedPath('/')) {
      next(firstPage);
    } else if (to.name === 'login' && token && sessionStarted) {
      next(firstPage);
    } else {
      next();
    }
  } catch (error) {
    console.error('Routing Error:', error);
    next(toVersionedPath('/not-found'));
  }
});

export default router;
