/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import vuetify from './vuetify';
import router from '../router';
import i18n from './i18n';
import Vue3Toastify, { toast } from 'vue3-toastify';
import axiosInstance from './axios';
import 'vue3-toastify/dist/index.css';
import VueDragscroll from 'vue-dragscroll';
import {
  ENVIRONMENT,
  LAST_COMMIT_HASH,
  TERMINAL_ID,
  VITE_SENTRY_DSN,
} from '../env';
import * as Sentry from '@sentry/vue';
import { browserTracingIntegration, replayIntegration } from '@sentry/vue';
import { pinia } from './pinia';

console.log('ENVIRONMENT:', ENVIRONMENT);

const ignoredErrors = [422, 404];
const whiteListedKeywords = ['Transaction'];

export function registerPlugins(app) {
  app
    .use(i18n)
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use(Vue3Toastify, {
      autoClose: 3000,
      limit: 3,
      position: toast.POSITION.BOTTOM_RIGHT,
    })
    .use(VueDragscroll);
  app.config.globalProperties.$axios = axiosInstance;

  ENVIRONMENT !== 'development' &&
    Sentry.init({
      app,
      beforeSend(event, hint) {
        const error = hint.originalException;
        if (!error) return event;

        const isWhiteListed = whiteListedKeywords.some((keyword) =>
          error.message.includes(keyword),
        );
        if (isWhiteListed) return event;

        const isIgnoredError = ignoredErrors.includes(error.status);
        if (isIgnoredError) return null;

        return event;
      },
      environment: ENVIRONMENT,
      dsn: VITE_SENTRY_DSN,
      integrations: [
        browserTracingIntegration({ router }),
        replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
          mastAllInputs: false,
        }),
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: [
        /^https:\/\/(?:[a-zA-Z0-9-]+\.)?trolley\.systems/,
        // 'localhost',
      ],
      // Session Replay
      replaysSessionSampleRate: ENVIRONMENT === 'dev' ? 1.0 : 0.1, // Capture 100% of the sessions in development, 10% in production
      replaysOnErrorSampleRate: 1.0, // if error occurs, capture 100% of the sessions
    });
  Sentry.setTags({
    tid: TERMINAL_ID,
    commit_hash: LAST_COMMIT_HASH,
  });
}
