import Dexie from 'dexie';
import {
  CancelablePromise,
  isEmpty,
  offlineClientTopic,
  TransactionsService,
} from '@trolley/api-sdk';

import { db } from '@trolley/db';
import { Session, SESSION_TYPE } from '@trolley/types';

const assignFilters = {
  min: (query: Dexie.Collection, min: number) =>
    query.filter((transaction) => transaction.total >= min),
  max: (query: Dexie.Collection, max: number) =>
    query.filter((transaction) => transaction.total <= max),

  paramEqual: (query: Dexie.Collection, param: string, value: any) =>
    query.filter((transaction) => transaction[param] === value),

  dateBetween: (query: Dexie.Collection, fromDate: string, toDate: string) =>
    query.filter((transaction) => {
      const transactionDate = new Date(transaction.created_at);
      return (
        transactionDate >= new Date(fromDate) &&
        transactionDate <= new Date(toDate)
      );
    }),
};

type TransactionsSyncResponse = {
  data: {
    session_ids: number[];
    transaction_ids: number[];
    token: string | null;
  };
};

export class OfflineTransactionsService extends TransactionsService {
  private getTransactionsForSession(session_id: number) {
    return db.localTransactions
      .where('session_id')
      .equals(session_id)
      .toArray();
  }

  private deleteSyncedRecords(response: TransactionsSyncResponse) {
    // sessions must by number[], trasactions must be string[].
    // type casting bug with dexie
    // eslint-disable-next-line prefer-const
    let { session_ids, transaction_ids } = response.data as unknown as {
      session_ids: number[];
      transaction_ids: string[] | number[];
    };

    // session_ids = session_ids.map(String);
    // cast to string
    transaction_ids = transaction_ids.map(String);

    console.log('Deleting synced records', session_ids, transaction_ids);

    Promise.all([
      db.sessions.bulkDelete(session_ids),
      // @ts-expect-error - this has to be string, or it won't work
      db.localTransactions.bulkDelete(transaction_ids),
    ]).catch((error) => {
      console.error('Error deleting synced records', error);
    });
  }

  // This method is used to sync offline transactions and sessions
  override postApiPosSyncTransactions() {
    return new CancelablePromise<void>(async (resolve, reject) => {
      try {
        const sessions = await db.sessions.toArray();

        if (isEmpty(sessions)) return resolve();

        for (const session of sessions) {
          const transactions = await this.getTransactionsForSession(session.id);
          Object.assign(session, { transactions });
        }

        const adaptedSessions = sessions.map(adaptSession);

        console.log('this.httpRequest.config.TOKEN', this.httpRequest.config);
        // Sync sessions and transactions with the server
        const response = (await super.postApiPosSyncTransactions({
          requestBody: {
            sessions: adaptedSessions,
          },
          createToken: this.httpRequest.config.TOKEN === 'offline' ? 1 : 0,
        })) as unknown as TransactionsSyncResponse;

        if (this.httpRequest.config.TOKEN === 'offline') {
          // if token is returned, update the token in the request config
          const { token } = response.data;
          if (token) offlineClientTopic.publish('NEW_SERVER_TOKEN', token);
          else offlineClientTopic.publish('SESSION_NOT_FOUND');
        }

        this.deleteSyncedRecords(response);

        console.log({ result: response });
        // @ts-expect-error - no data type
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  override getApiPosTransactionsGetoneById({ id }: { id: string }) {
    return new CancelablePromise<Record<string, unknown>>(
      async (resolve, reject) => {
        try {
          const localTransaction = await db.localTransactions
            .where('id')
            .equals(id)
            .first();

          if (localTransaction) {
            resolve({ data: localTransaction });
          } else {
            const onlineTransaction =
              await super.getApiPosTransactionsGetoneById({
                id,
              });
            resolve(onlineTransaction);
          }
        } catch (error) {
          reject(error);
        }
      },
    );
  }

  override getApiPosTransactionsGetbybranch(
    props: any,
  ): CancelablePromise<Record<string, unknown>> {
    return new CancelablePromise<Record<string, unknown>>(
      async (resolve, reject) => {
        const {
          page = 1,
          perPage = 25,
          min,
          max,
          date,
          paymentMethodId,
          uniqueCode,
        } = props;
        const offset = (page - 1) * perPage;
        const query = db.localTransactions.orderBy('created_at').reverse();

        if (min) assignFilters.min(query, min);
        if (max) assignFilters.max(query, max);

        if (paymentMethodId)
          assignFilters.paramEqual(query, 'payment_method_id', paymentMethodId);

        if (uniqueCode)
          assignFilters.paramEqual(query, 'unique_code', uniqueCode);

        if (date) {
          const fromDateStr = date.split(',')[0];
          let toDateStr = date.split(',')[1];
          const todayDate = new Date().toISOString().split('T')[0];

          // if toDate is today's date, set the time to the current time
          if (toDateStr === todayDate) toDateStr = new Date().toISOString();

          assignFilters.dateBetween(query, fromDateStr, toDateStr);
        }

        let filteredOfflineTransactions = await query.toArray();

        filteredOfflineTransactions = filteredOfflineTransactions.filter(
          // @ts-expect-error - type to be fixed
          (transaction) => !transaction.void_reason_id,
        );

        const paginatedOfflineTransactions = filteredOfflineTransactions.slice(
          offset,
          offset + perPage,
        );

        // If sufficient offline data exists, resolve with it
        if (paginatedOfflineTransactions.length === perPage) {
          resolve({
            success: true,
            data: {
              transactions: paginatedOfflineTransactions,
              pagination: {
                current_page: page,
                last_page: Math.ceil(
                  filteredOfflineTransactions.length / perPage,
                ),
                total: filteredOfflineTransactions.length,
                per_page: perPage,
              },
            },
          });
          return;
        }
        try {
          // Fetch online transactions if offline data is insufficient
          const onlineResponse = await super.getApiPosTransactionsGetbybranch({
            ...props,
            offlineRecordsCount: await db.localTransactions.count(),
          });
          const onlineTransactions = onlineResponse.data.transactions;

          const combinedTransactions = [
            ...paginatedOfflineTransactions,
            ...onlineTransactions,
          ];

          resolve({
            data: {
              transactions: combinedTransactions,
              pagination: {
                current_page: page,
                last_page: onlineResponse.data.pagination.last_page,
                total:
                  filteredOfflineTransactions.length +
                  onlineResponse.data.pagination.total,
                per_page: perPage,
              },
            },
          });
        } catch (error) {
          resolve({
            data: {
              transactions: paginatedOfflineTransactions,
              pagination: {
                current_page: page,
                last_page: Math.ceil(
                  filteredOfflineTransactions.length / perPage,
                ),
                total: filteredOfflineTransactions.length,
                per_page: perPage,
              },
            },
          });
        }
      },
    );
  }
}

const adaptSession = (session: Session) => {
  if (session.type === SESSION_TYPE.OFFLINE) {
    return {
      ...session,
      local_id: session.id,
      id: undefined,
    };
  }

  return session;
};
