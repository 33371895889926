import { z } from 'zod';
import { ref } from 'vue';
import type { HealthStatus } from '../sync-check/use-health-check';
import { usePaymentMethods } from '../../modules/payments/health-store';

export const paymentMethodSchema = z.object({
  id: z.number(),
  slug: z.string(),
  requires_code: z.boolean(),
  is_scanned: z.boolean(),
  loyalty_support: z.boolean(),
  splittable: z.boolean(),
  repeatable: z.boolean(),
  name: z.string(),
  name_ar: z.string(),
  payment_method_id: z.number(),
});

export const usePaymentMethodsCheck = async () => {
  const status = ref<HealthStatus>('loading');
  const count = ref(0);

  const paymentMethods = usePaymentMethods().paymentMethods;
  if (!paymentMethods.length) await usePaymentMethods().fetchPaymentMethods();

  const paymentMethodsHealth = Promise.all(
    paymentMethods.map((item) => paymentMethodSchema.safeParseAsync(item)),
  );

  const results = await paymentMethodsHealth;

  status.value = results.every((result) => {
    if (!result.success) {
      console.error(result.error);
    }
    return result.success;
  })
    ? 'ready'
    : 'error';
  count.value = results.length;

  return {
    status,
    label: 'Payment Methods' as const,
    count,
  };
};
