import { IItem } from '@trolley/types';

export class Item implements IItem {
  id: number;
  name: string;
  name_ar: string;
  price: number;
  price_before_offer: number;
  redeemable: boolean;
  barcodes: Record<string, any>;
  quantity: number;
  barcodeIds: string[];
  discount: number;
  upc: string | null;

  canBeIncreased = true;
  canBeDecreased = true;
  isEditable = true;

  constructor(item: IItem, targetUPC?: string) {
    this.id = item.id;
    this.name = item.name;
    this.name_ar = item.name_ar;
    this.price = item.price;
    this.price_before_offer = item.price_before_offer;
    this.redeemable = item.redeemable;
    this.barcodes = item.barcodes;
    this.barcodeIds = item.barcodeIds;
    this.quantity = item.quantity || 1;

    if (targetUPC) {
      this.quantity = this.getComputedValues(targetUPC).quantity;
    }

    this.discount = this.getComputedValues(targetUPC).discount;
    this.upc = this.getComputedValues(targetUPC).upc;
  }

  private getComputedValues(scannedUPC?: string) {
    let discount = 0;
    if (this.price_before_offer > 0) {
      discount = this.roundDiscount(
        ((this.price_before_offer - this.price) / this.price_before_offer) *
          100,
      );
    } else {
      discount = 0;
    }

    const targetedUPC = this.safeParseBarcode(scannedUPC);
    const quantity = targetedUPC ? targetedUPC.quantity : 1;
    const upc = targetedUPC ? targetedUPC.id : null;
    return { quantity, discount, upc };
  }

  private safeParseBarcode(scannedUPC?: string) {
    if (!this.barcodes || Object.keys(this.barcodes).length === 0) {
      return undefined;
    }

    if (scannedUPC && this.barcodes[scannedUPC]) {
      return this.barcodes[scannedUPC];
    }

    // if no UPC is provided, return the lowest quantity barcode
    const sortedBarcodes = Object.values(this.barcodes).sort(
      (a, b) => a.quantity - b.quantity,
    );
    return sortedBarcodes[0];
  }

  private roundDiscount(discount: number) {
    return Math.round(discount * 100) / 100;
  }
}
