import { CancelablePromise } from '../online-client';
import { isEmpty, startInterval } from './helpers';
import Dexie from 'dexie';
import { useLocalStorage } from '@vueuse/core';
import { publish, subscribe } from '@enegix/events';

export const SyncHealthCheck = useLocalStorage(`sync-health`, {});

abstract class StorageAdapter<T> {
  abstract clear(): Promise<void>;

  abstract add(data: T | T[]): Promise<void>;

  abstract get(): Promise<T[] | T | null>;

  abstract get entityName(): string;
}

export class DexieStorageAdapter<T> implements StorageAdapter<T> {
  constructor(private table: Dexie.Table) {
    subscribe(`clear-cache-${this.table.name}`, this.clear.bind(this));
  }

  get entityName() {
    return `${this.table.name}-health`;
  }

  async clear(): Promise<void> {
    await this.table.clear();
  }

  async add(data: T | T[]): Promise<void> {
    if (Array.isArray(data)) {
      await this.table.bulkPut(data);
    } else {
      await this.table.put(data);
    }
  }

  async get(): Promise<T[]> {
    return this.table.toArray();
  }
}

export class LocalStorageAdapter<T> implements StorageAdapter<T> {
  constructor(private key: string) {
    subscribe(`clear-cache-${this.key}`, this.clear.bind(this));
  }

  get entityName() {
    return `${this.key}-health`;
  }

  async clear(): Promise<void> {
    localStorage.removeItem(this.key);
  }

  async add(data: T | T[]): Promise<void> {
    localStorage.setItem(this.key, JSON.stringify(data));
  }

  async get(): Promise<T | null> {
    const rawData = localStorage.getItem(this.key);
    return rawData ? JSON.parse(rawData) : null;
  }
}

// Main caching class
export class IntervalFetchAndCache<T> {
  constructor(
    private storage: StorageAdapter<T>,
    private fetchFromApi: (...props: any[]) => CancelablePromise<any>,
    private beforeCacheDataAdapter: (data: any) => T = (data) => data,
    private afterRequestDataAdapter: (data: any, isOnline: boolean) => T = (
      data,
    ) => data,
    isSyncEnabled = true,
  ) {
    if (isSyncEnabled) startInterval(this.fetchAndCache.bind(this));
  }

  private async fetchAndCache(...props: any[]): Promise<{ data: T }> {
    SyncHealthCheck.value = {
      ...SyncHealthCheck.value,
      [this.storage.entityName]: { isFetching: true, isCached: false },
    };
    const response = await this.fetchFromApi(...props);
    await this.storage.clear();
    const adaptedData = this.beforeCacheDataAdapter(response.data);

    await this.storage.add(adaptedData);

    SyncHealthCheck.value = {
      ...SyncHealthCheck.value,
      [this.storage.entityName]: { isFetching: false, isCached: true },
    };

    return { ...response, data: adaptedData };
  }

  get isCached() {
    const isCachedRaw = localStorage.getItem('sync-health');
    const isCachedObject = JSON.parse(isCachedRaw ?? '{}');
    return isCachedObject[this.storage.entityName]?.isCached ?? false;
  }

  // Get data with optional caching
  public getData(...props: any): CancelablePromise<T> {
    return new CancelablePromise(async (resolve, reject) => {
      try {
        // @ts-expect-error - no data type
        if (this.isCached) resolve({ data: await this.storage.get() });
        else {
          const fetchedData = await this.fetchAndCache(...props);
          // @ts-expect-error - no data type
          resolve(fetchedData);
        }
      } catch (error) {
        reject(error);
      }
    });
  }
}

// Abstract class for change synchronization
abstract class IntervalFetchAndCacheChanges<T> {
  constructor(
    protected storage: StorageAdapter<T>,
    protected fetchFromApi: (...props: any[]) => CancelablePromise<any>,
    protected beforeCacheDataAdapter: (data: any) => T = (data) => data,
  ) {
    startInterval(() =>
      this.syncModifiedChanges().then(() =>
        publish(`interval-sync-${this.storage.entityName}`),
      ),
    );
  }

  protected abstract syncModifiedChanges(): Promise<void>;
}

// Adds changes to the cache WITHOUT clearing the store
export class StartIntervalFetchAndCacheChangesPatch<
  T,
> extends IntervalFetchAndCacheChanges<T> {
  protected async syncModifiedChanges(): Promise<void> {
    const response = await this.fetchFromApi();
    const adaptedData = this.beforeCacheDataAdapter(response.data);

    await this.storage.add(adaptedData);
  }
}

// Clears the store and adds the data again
export class StartIntervalFetchAndCacheChangesPut<
  T,
> extends IntervalFetchAndCacheChanges<T> {
  protected async syncModifiedChanges(): Promise<void> {
    const response = await this.fetchFromApi();
    if (isEmpty(response.data)) return;

    const adaptedData = this.beforeCacheDataAdapter(response.data);

    await this.storage.clear();
    await this.storage.add(adaptedData);
  }
}

// Fetch and cache once
export class FetchAndCacheOnce {
  static create<T>(
    storage: StorageAdapter<T>,
    fetchFromApi: (...props: any[]) => CancelablePromise<any>,
    beforeCacheDataAdapter?: (data: any) => T,
    afterRequestDataAdapter?: (data: any, isOnline: boolean) => T,
  ): IntervalFetchAndCache<T> {
    return new IntervalFetchAndCache(
      storage,
      fetchFromApi,
      beforeCacheDataAdapter,
      afterRequestDataAdapter,
      false, // Run only once
    );
  }
}
