import Dexie, { type EntityTable } from 'dexie';
import type {
  CachedPaymentMethod,
  Category,
  IProduct,
  IUser,
  IVoidReason,
  OfflineSession,
  Transaction,
} from '@trolley/types';
import { applyEncryptionMiddleware, NON_INDEXED_FIELDS } from 'dexie-encrypted';
import fakeIndexedDB from 'fake-indexeddb';

export class TrolleyDatabase extends Dexie {
  voidReasons!: EntityTable<IVoidReason, 'id'>;
  localTransactions!: EntityTable<Transaction, 'id'>;
  quickList!: EntityTable<Category, 'id'>;
  paymentMethods!: EntityTable<CachedPaymentMethod, 'id'>;
  items!: EntityTable<IProduct, 'id'>;
  sessions!: EntityTable<OfflineSession, 'id'>;
  users!: EntityTable<IUser, 'id'>;

  constructor() {
    super('TrolleyDatabase');
    applyEncryptionMiddleware(
      this,
      // TODO: replace with a real key from .env
      new Uint8Array(new Array(32).fill(0)),
      // @ts-expect-error - to be fixed
      {
        users: NON_INDEXED_FIELDS,
        localTransactions: NON_INDEXED_FIELDS,
      },
      (key) => {
        console.log('key', key);
      },
    );

    this.version(1).stores({
      quickList: 'id',
      voidReasons: 'id',
      paymentMethods: 'id',
      items: 'id, *barcodeIds',
      localTransactions: 'id, *created_at, *session_id',
      sessions: 'id, *started_at',
      users: 'id, *username, *pos_user_id',
    });
  }
}
// for testing
if (process.env['NODE_ENV'] === 'test') {
  TrolleyDatabase.dependencies.indexedDB = fakeIndexedDB;
  TrolleyDatabase.dependencies.IDBKeyRange = require('fake-indexeddb/lib/FDBKeyRange');
}
export const db = new TrolleyDatabase();
