import { ICoupon } from '@trolley/types';
import { trolleyClient } from '@trolley/api-sdk';
import { PendingTransaction } from './pending-transaction';
import { formatPrice, toFloat } from '@trolley/utils';
import { toast } from 'vue3-toastify';

type ApplyCouponParams = {
  coupon: ICoupon;
  employee_id?: string;
  customer_id?: number;
};

export class CouponManager {
  private transaction: PendingTransaction;
  private excessDiscount = 0;

  constructor(transaction: PendingTransaction) {
    this.transaction = transaction;
  }

  async applyCoupon(props: ApplyCouponParams) {
    this.clearCoupon();

    const discountAmount = await this.getCouponValue(props);

    // get the remaining amount to be paid, if less than 0
    // then remove the excess amount from loyalty points
    this.excessDiscount = Math.max(
      discountAmount - this.transaction.itemsManager.totalDue,
      0,
    );

    this.excessDiscount && this.handleExcessDiscount();

    this.transaction.itemsManager.setActiveDiscount(discountAmount);
    this.transaction.coupon = props.coupon.code;
    this.transaction.employee_id = props.employee_id;
  }

  clearCoupon() {
    this.transaction.itemsManager.setActiveDiscount(0);
    this.transaction.itemsManager.setRedeemedAmount(
      toFloat(
        this.transaction.itemsManager.redeemedAmount + this.excessDiscount,
      ),
    );
    this.excessDiscount = 0;
    this.transaction.coupon = undefined;
    this.transaction.employee_id = undefined;
  }

  handleExcessDiscount() {
    const newRedeemedAmount = Math.max(
      this.transaction.itemsManager.redeemedAmount - this.excessDiscount,
      0,
    );

    toast.info(
      `Redeemed amount has been reduced from ${formatPrice(this.transaction.itemsManager.redeemedAmount)} to ${formatPrice(newRedeemedAmount)} to use the full amount of the discount coupon.`,
    );

    this.transaction.itemsManager.setRedeemedAmount(newRedeemedAmount);
  }

  async getCouponValue({
    coupon,
    employee_id,
    customer_id,
  }: ApplyCouponParams) {
    const res = await trolleyClient.discounts.postApiPosDiscountsCalculate({
      requestBody: {
        coupon: coupon.code,
        items: this.transaction.items,
        customer_id,
        employee_id,
      },
    });
    return res.data.discount_amount as number;
  }
}
