import { type TrolleyClient } from '@trolley/api-sdk';
import { Item } from './item';
import { IItem } from '@trolley/types';
import { wait } from 'nx-cloud/lib/utilities/waiter';
import { ItemsStrategy, SaleItemsStrategy } from './strategies';
import { ItemsCalculator } from './items-calculator';
import { toFloat } from '@trolley/utils';
import { toast } from 'vue3-toastify';

export class ItemsManager {
  strategy: ItemsStrategy;
  get items() {
    return this.strategy.items;
  }
  get redeemableItems() {
    return this.items.filter((item) => item.redeemable);
  }
  totalItems = 0;
  totalDue = 0;
  subTotal = 0;
  totalEarnedPoints = 0;
  irredeemableAmount = 0;
  redeemableAmount = 0;
  amountBeforeDiscount = 0;
  redeemedAmount = 0;

  totalPassiveDiscount = 0;
  totalActiveDiscount = 0;
  totalDiscount = 0;

  isLocked = false;

  constructor() {
    this.strategy = new SaleItemsStrategy();
    this.updateCalculations();
  }

  lock() {
    this.isLocked = true;
  }

  unlock() {
    this.isLocked = false;
  }

  setStrategy(strategy: ItemsStrategy) {
    this.strategy = strategy;
  }

  setRedeemedAmount(amount: number) {
    this.redeemedAmount = toFloat(amount);
    this.updateCalculations();
  }

  setActiveDiscount(amount: number) {
    this.totalActiveDiscount = amount;
    this.updateCalculations();
  }

  addItem(...args: Parameters<ItemsStrategy['addItem']>) {
    if (this.isLocked) return this.showLockedMessage();

    this.strategy.addItem(...args);
    this.updateCalculations();
  }

  removeItem(id: number) {
    if (this.isLocked) return this.showLockedMessage();

    if (!this.hasItem(id)) return;
    this.strategy.removeItem(id);
    this.updateCalculations();

    if (this.items.length === 0) {
      this.clear();
    }
  }

  increaseQuantity(id: number, amount = 1, scannedUPC?: string) {
    if (this.isLocked) return this.showLockedMessage();

    if (!this.hasItem(id)) return;
    this.strategy.increaseQuantity(id, amount, scannedUPC);
    this.updateCalculations();
  }

  decreaseQuantity(id: number) {
    if (this.isLocked) return this.showLockedMessage();

    if (!this.hasItem(id)) return;
    this.strategy.decreaseQuantity(id);
    this.updateCalculations();
  }

  bulkAddItems(items: IItem[]) {
    if (this.isLocked) return this.showLockedMessage();

    for (const item of items) {
      this.addItem(item);
    }
  }

  async fancyBulkAddItems(items: IItem[]) {
    if (this.isLocked) return this.showLockedMessage();

    for (const item of items) {
      this.addItem(item);
      await wait(180);
    }
  }

  addItemByUPC(upc: string, trolleyClient: TrolleyClient) {
    if (this.isLocked) return this.showLockedMessage();

    trolleyClient.items
      .getApiPosItemById({
        id: upc,
      })
      .then((res) => {
        const product = res['data'] as Item;
        if (product) this.addItem(product, upc);
      });
  }

  clear() {
    this.unlock();
    this.strategy.items.splice(0);
    this.updateCalculations();
    this.setRedeemedAmount(0);
    this.setActiveDiscount(0);
  }

  getItem(id: number) {
    const item = this.items.find((item) => item.id === id);
    if (item) return item;
    return;
  }

  hasItem(id: number) {
    return this.items.some((item) => item.id === id);
  }

  updateCalculations() {
    const calculated = ItemsCalculator.calculate(
      this.items,
      this.redeemedAmount,
      this.totalActiveDiscount,
    );

    this.totalItems = calculated.totalItems;
    this.subTotal = calculated.subTotal;
    this.totalPassiveDiscount = calculated.totalPassiveDiscount;
    this.totalDiscount = calculated.totalDiscount;
    this.totalEarnedPoints = calculated.totalEarnedPoints;
    this.irredeemableAmount = calculated.irredeemableAmount;
    this.redeemableAmount = calculated.redeemableAmount;
    this.amountBeforeDiscount = calculated.amountBeforeDiscount;
    this.totalDue = calculated.totalDue;
  }

  private showLockedMessage() {
    toast.error('This cart is locked.');
    console.warn('This cart is locked.');
  }
}
