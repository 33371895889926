import { CancelablePromise, DefaultService } from '../../online-client';
import { db, getCurrentLocalSession } from '@trolley/db';
import { SESSION_TYPE } from '@trolley/types';
import { compare } from 'bcryptjs';
import { customAlphabet } from 'nanoid';
import { offlineClientTopic } from '../events';

export class OfflineDefaultService extends DefaultService {
  private lastLoginPosUserId: number | undefined;
  private isLastLoginOnline: boolean | undefined;

  constructor(props: ConstructorParameters<typeof DefaultService>[0]) {
    super(props);

    getCurrentLocalSession().then((currentLocalSession) => {
      if (!currentLocalSession) this.tryCacheLastOnlineSession();
    });
  }

  private async tryCacheLastOnlineSession() {
    try {
      const { data: currentSession } = await super.getApiPosCurrentSession();
      await db.sessions.put({
        ...currentSession,
        type: SESSION_TYPE.ONLINE,
      });
    } catch (e) {
      console.error('tryCacheLastOnlineSession', e);
    }
  }

  // online first
  override postApiPosLogin(
    data: Parameters<DefaultService['postApiPosLogin']>[0],
  ) {
    return new CancelablePromise<Record<string, unknown>>((resolve, reject) => {
      // login online
      return (
        super
          .postApiPosLogin(data)
          .then((response) => {
            // @ts-expect-error - typing to be fixed
            this.lastLoginPosUserId = response.cashier_id;
            this.isLastLoginOnline = true;
            resolve(response);
          })
          // try offline login
          .catch(async (reason) => {
            if (reason.message !== 'Network Error') return reject(reason);
            if (!data || !data.formData)
              throw new Error('No form data provided');

            const { username, password } = data.formData;
            const user = await db.users.where({ username }).first();

            if (!user || !password) {
              reject({ message: 'Error Invalid Credentials [OFFLINE]' });
              return offlineClientTopic.publish(
                'SHOW_ERROR',
                'Invalid Credentials [OFFLINE]',
              );
            }

            compare(String(password), user.password, async (err, res) => {
              const { password, ...userWithoutPassword } = user;
              if (res) {
                this.lastLoginPosUserId = user.id;
                this.isLastLoginOnline = false;
                resolve({ ...userWithoutPassword, token: 'offline' });
                return;
              } else {
                reject({ message: 'Error Invalid Credentials [OFFLINE]' });
                return offlineClientTopic.publish(
                  'SHOW_ERROR',
                  'Invalid Credentials [OFFLINE]',
                );
              }
            });
          })
      );
    });
  }

  // online first
  override postApiPosCashIn(
    data: Parameters<DefaultService['postApiPosCashIn']>[0],
  ) {
    if (
      this.lastLoginPosUserId === undefined ||
      this.isLastLoginOnline === undefined
    ) {
      return new CancelablePromise<Record<string, unknown>>(
        (resolve, reject) => {
          reject(new Error('Login first'));
          offlineClientTopic.publish('SESSION_NOT_FOUND');
        },
      );
    }

    return new CancelablePromise(async (resolve, reject) => {
      try {
        // if login was offline, throw error to go in the catch block
        if (!this.isLastLoginOnline) throw new Error('Login was offline');

        // Attempt to process the online cash-in
        const response = await super.postApiPosCashIn(data);
        await db.sessions.put({
          ...response,
          id: response.session_id,
          type: SESSION_TYPE.ONLINE,
          started_at: new Date().toISOString(),
          pos_user_id: this.lastLoginPosUserId,
        });
        resolve(response);
      } catch (reason) {
        console.log('reason', reason);

        // Fallback to offline session handling
        if (!data || !data.formData) {
          // Log or handle the error here as needed without rejecting
          console.warn('No form data provided for offline session.');
          resolve({
            session_id: null,
            error: 'No form data provided',
          });
          return;
        }

        const offlineSession = {
          id: Number(customAlphabet('1234567890', 6)()),
          type: SESSION_TYPE.OFFLINE,
          cash_in: data?.formData?.cash_in,
          started_at: new Date().toISOString(),
          created_at: new Date().toISOString(),
          pos_user_id: this.lastLoginPosUserId,
        };

        await db.sessions.put(offlineSession);

        resolve({ session_id: offlineSession.id });
      }
    });
  }

  override postApiPosLogout(
    data: Parameters<DefaultService['postApiPosLogout']>[0],
  ) {
    return new CancelablePromise<Record<string, unknown>>(
      async (resolve, reject) => {
        const currentSession = await getCurrentLocalSession();
        console.log('currentSession', currentSession);
        try {
          const response = await super.postApiPosLogout(data);
          if (currentSession) {
            await db.sessions.put({
              ...currentSession,
              ...data?.formData,
              type: currentSession.type,
              ended_at: new Date().toISOString(),
            });
          }
          resolve(response);
        } catch (e) {
          // offline
          if (currentSession) {
            await db.sessions.put({
              ...currentSession,
              ...data?.formData,
              type: currentSession.type,
              ended_at: new Date().toISOString(),
            });
          }
          resolve({});
        }
      },
    );
  }
}
