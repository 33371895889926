import { toFloat } from '@trolley/utils';
import { Item } from '@trolley/items';

export class ItemsCalculator {
  static calculate(
    items: Item[],
    redeemedAmount = 0,
    totalActiveDiscount = 0,
    // redeemPointsFactor = 0.003,
    earnPointsFactor = 10,
  ) {
    const totalItems = items.reduce(
      (acc, product) => acc + product.quantity,
      0,
    );

    const subTotal = toFloat(
      items.reduce(
        (acc, product) =>
          acc +
          (product.price_before_offer || product.price) * product.quantity,
        0,
      ),
    );

    const totalPassiveDiscount = toFloat(
      items
        .filter((item) => item.discount > 0)
        .reduce(
          (acc, item) =>
            acc + item.quantity * (item.price_before_offer - item.price),
          0,
        ),
    );

    const totalDiscount = toFloat(totalPassiveDiscount + totalActiveDiscount);

    const irredeemableAmount = toFloat(
      items.reduce(
        (acc, product) =>
          acc + (product.redeemable ? 0 : product.price * product.quantity),
        0,
      ),
    );

    const redeemableItems = items.filter((item) => item.redeemable);
    const redeemableAmount = toFloat(
      redeemableItems.reduce(
        (acc, product) => acc + product.price * product.quantity,
        0,
      ),
    );

    const amountBeforeDiscount = toFloat(
      items.reduce(
        (acc, product) => acc + product.price_before_offer * product.quantity,
        0,
      ),
    );

    const totalDue = Math.max(
      toFloat(subTotal - redeemedAmount - totalDiscount),
      0,
    );

    const totalEarnedPoints = Math.max(
      Math.floor(toFloat(totalDue - irredeemableAmount) * earnPointsFactor),
      0,
    );

    return {
      totalItems,
      subTotal,
      totalPassiveDiscount,
      totalDiscount,
      totalEarnedPoints,
      irredeemableAmount,
      redeemableAmount,
      amountBeforeDiscount,
      totalDue,
    };
  }
}
