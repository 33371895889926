import {
  adaptLocalOrder,
  CancelablePromise,
  offlineClientTopic,
  OrdersService,
} from '@trolley/api-sdk';
import { isAutoKnetMethod } from '@trolley/knet';
import { db, getCurrentLocalSession } from '@trolley/db';
import { CreateTransactionPayload } from '@trolley/types';
import { resolveAfterDelay } from '@trolley/utils';

export class OfflineOrdersService extends OrdersService {
  override postApiPosTransactionsStore(
    data: Parameters<OrdersService['postApiPosTransactionsStore']>[0],
  ) {
    return new CancelablePromise<Record<string, unknown>>((resolve, reject) => {
      const { requestBody } = data as unknown as {
        requestBody: CreateTransactionPayload;
      };
      const hasRedeemedAmount = requestBody.redeem_amount > 0;
      const hasCoupon = !!requestBody.coupon;

      // If no customer, remove redeem points
      if (!requestBody.phone) {
        requestBody.redeem_amount_points = 0;
      }

      const promise =
        isAutoKnetMethod(requestBody) || hasRedeemedAmount || hasCoupon
          ? super.postApiPosTransactionsStore(data as Record<string, unknown>)
          : this.createOfflineOrder(requestBody);

      return resolveAfterDelay(promise).then(resolve).catch(reject);
    });
  }

  private createOfflineOrder(requestBody: CreateTransactionPayload) {
    return new CancelablePromise<Record<string, unknown>>(
      async (resolve, reject) => {
        try {
          const adaptedLocalTransaction = await adaptLocalOrder(requestBody);

          const session = await getCurrentLocalSession();
          if (session)
            Object.assign(adaptedLocalTransaction, { session_id: session.id });
          else {
            offlineClientTopic.publish('SESSION_NOT_FOUND');
            return;
          }

          // @ts-expect-error - id has to be string
          await db.localTransactions.add(adaptedLocalTransaction);
          resolve({ data: adaptedLocalTransaction });
        } catch (error) {
          reject(error);
        }
      },
    );
  }
}
