import type { KnetLog } from './types';
import { PAYMENT_METHODS } from '@trolley/types';

export const isAutoKnetMethod = (order: {
  payments: { payment_method_id: number }[];
  payment_method_id?: number;
}) => {
  console.log('order', order);
  return (
    order.payments.some(
      (payment) => payment.payment_method_id === PAYMENT_METHODS.AUTO_KNET,
    ) ||
    // TODO: remove this when the backend is updated
    order?.payment_method_id === PAYMENT_METHODS.AUTO_KNET
  );
};

export const isKnetPaymentDeclined = (log: KnetLog) =>
  log['@ActionCode'] === 'DECLINE';

export const safeAccess = (obj: any, key: string) =>
  obj && obj[key] ? obj[key] : null;

export const isCashPaymentMethod = (paymentMethodId: number) =>
  paymentMethodId === PAYMENT_METHODS.CASH;
