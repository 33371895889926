import { ref } from 'vue';
import type { HealthStatus } from '../sync-check/use-health-check';
import { z } from 'zod';
import { itemsStore } from './store';
import { getCachedEntity } from '@trolley/api-sdk';

export const itemSchema = z.object({
  id: z.number(),
  name: z.string(),
  price: z.number(),
  price_before_offer: z.number(),
  redeemable: z.boolean(),
  barcodeIds: z.array(z.string()),
});

export const useItemsCheck = async () => {
  const status = ref<HealthStatus>('loading');
  const count = ref(0);
  const items = await getCachedEntity('items');

  if (!items.length) await itemsStore().fetchAllItems();

  const itemsHealth = Promise.all(
    itemsStore().allItems.map((item) => itemSchema.safeParseAsync(item)),
  );

  const results = await itemsHealth;

  status.value = results.every((result) => {
    if (!result.success) {
      console.error(result.error);
    }
    return result.success;
  })
    ? 'ready'
    : 'error';
  count.value = results.length;

  return {
    status,
    label: 'Items' as const,
    count,
  };
};
