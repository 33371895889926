import { ItemsManager } from '@trolley/items';
import { ActiveTransaction } from '@trolley/transactions';

export class StashedTransaction {
  // random 10 numbers
  id = Math.floor(Math.random() * 10000000000);
  time = new Date().getTime();
  itemsManager: ItemsManager;

  constructor(transaction: ActiveTransaction) {
    this.itemsManager = transaction.itemsManager;
  }
}

export class StashManager {
  static addStash(stash: StashedTransaction) {
    const stashedTransactions = JSON.parse(
      localStorage.getItem('stashedTransactions') || '[]',
    );

    localStorage.setItem(
      'stashedTransactions',
      JSON.stringify([stash, ...stashedTransactions]),
    );
  }

  static getStash(stashId: number) {
    const stashedTransactions = JSON.parse(
      localStorage.getItem('stashedTransactions') || '[]',
    ) as StashedTransaction[];
    return stashedTransactions.find((stash) => stash.id === stashId);
  }

  static removeStash(stashId: number) {
    const stashedTransactions = JSON.parse(
      localStorage.getItem('stashedTransactions') || '[]',
    ) as StashedTransaction[];
    const updatedStashedSales = stashedTransactions.filter(
      (stash) => stash.id !== stashId,
    );
    localStorage.setItem(
      'stashedTransactions',
      JSON.stringify(updatedStashedSales),
    );
  }
}
