import { type BaseHttpRequest, PaymentMethodsService } from '@trolley/api-sdk';
import {
  DexieStorageAdapter,
  FetchAndCacheOnce,
  IntervalFetchAndCache,
  StartIntervalFetchAndCacheChangesPut,
} from '../interval-fetch-and-cache';
import { CachedPaymentMethod } from '@trolley/types';
import { db } from '@trolley/db';

export class OfflinePaymentMethodsService extends PaymentMethodsService {
  private offlinePaymentMethods: IntervalFetchAndCache<Record<string, unknown>>;

  constructor(request: BaseHttpRequest) {
    super(request);
    this.offlinePaymentMethods = FetchAndCacheOnce.create(
      new DexieStorageAdapter(db.paymentMethods),
      () => super.getApiPosSyncPaymentMethods.call(this, { all: 1 }),
      (data) =>
        data.map((method: CachedPaymentMethod) => ({
          ...method,
          payment_method_id: method.id,
        })),
    );

    new StartIntervalFetchAndCacheChangesPut(
      new DexieStorageAdapter(db.paymentMethods),
      super.getApiPosSyncPaymentMethods.bind(this),
      (data) =>
        data.map((method: CachedPaymentMethod) => ({
          ...method,
          payment_method_id: method.id,
        })),
    );
  }

  override getApiPosTransactionsPaymentMethods() {
    return this.offlinePaymentMethods.getData();
  }
}
