import { type CachedPaymentMethod, PAYMENT_METHODS } from '@trolley/types';

const requiresCashPaymentMethods = [PAYMENT_METHODS.CASH];
const requiresCheckToEnable = [PAYMENT_METHODS.AUTO_KNET];
const inputNames = [
  {
    id: PAYMENT_METHODS.MANUAL_KNET,
    newName: 'Auth',
  },
];

export const paymentMethodAdapter = (
  paymentMethod: CachedPaymentMethod,
): CachedPaymentMethod => ({
  ...paymentMethod,
  requires_cash: requiresCashPaymentMethods.includes(paymentMethod.id),
  requires_code:
    paymentMethod.id === PAYMENT_METHODS.CASH
      ? false
      : paymentMethod.requires_code,
  disabled: requiresCheckToEnable.includes(paymentMethod.id),
  inputName:
    inputNames.find(({ id }) => id === paymentMethod.id)?.newName ||
    paymentMethod.name,
  readable_payment_method: paymentMethod.name,
});
