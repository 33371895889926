/**
 * Unique Code Generator
 * Generates a unique code based on warehouse ID, terminal sequence, and the current UNIX timestamp.
 * Used for generating unique codes for offline transactions.
 * Format: warehouse_id(4) + terminal_sequence(1) + unix_timestamp(10) => 15 characters.
 */
export const UniqueCodeGeneratorConfig: {
  warehouseId: string | number | undefined;
  terminalSequence: string | number | undefined;
} = {
  warehouseId: 1111,
  terminalSequence: 1,
};

export class UniqueCodeGenerator {
  private get unixTimestamp() {
    return Math.floor(Date.now() / 1000);
  }

  generate({ terminalSequence, warehouseId } = UniqueCodeGeneratorConfig) {
    if (!warehouseId || !terminalSequence) {
      throw new Error(
        `warehouseId and terminalSequence are required, got ${warehouseId} and ${terminalSequence}`,
      );
    }

    return `${warehouseId}${terminalSequence}${this.unixTimestamp}`;
  }
}

export const uniqueCode = new UniqueCodeGenerator();

export const assignUniqueCodeGeneratorConfig = (
  config: typeof UniqueCodeGeneratorConfig,
) => {
  console.log('assigning new config', config);
  Object.assign(UniqueCodeGeneratorConfig, config);
};
