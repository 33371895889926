export const SHOW_SUSPEND_DIALOG = 'SHOW_SUSPEND_DIALOG';
export const SHOW_VOID_DIALOG = 'SHOW_VOID_DIALOG';

export enum EMPLOYEE_REQUESTS {
  VOID_SALE = 'VOID_SALE',
  STASH_SALE = 'STASH_SALE',
  PRINT_DECLINED_RECEIPT = 'PRINT_DECLINED_RECEIPT',
}

export enum CART_ACTIONS {
  SHOW_CART_COUPONS = 'SHOW_CART_COUPONS',
}
