import { Transaction } from './transaction';
import { ITransactionResponse, TRANSACTION_STATUS } from '@trolley/types';
import { KnetResponse } from '@trolley/knet';
import { publish } from '@enegix/events';

export class CompletedTransaction
  extends Transaction
  implements ITransactionResponse
{
  id: number;
  unique_code: string;
  status: number;
  readable_status: string;
  cashier_id: string;
  cashier_name: string;
  customer_name: string;
  transaction_type: number;
  transaction_type_text: string;
  discount_value: number;
  items_count: number;
  created_at: string;
  readable_payment_method: string;
  last_payment_id: number;

  knetResponse?: KnetResponse;
  auth_id?: string;
  card_number?: string;
  card_name?: string;
  merchant_id?: string;
  payment_id?: number;

  get isPaid() {
    return this.status === TRANSACTION_STATUS.PAID;
  }

  constructor(transaction: ITransactionResponse) {
    super(transaction);
    this.id = transaction.id;
    this.unique_code = transaction.unique_code;
    this.status = transaction.status;
    this.readable_status = transaction.readable_status;
    this.cashier_id = transaction.cashier_id;
    this.cashier_name = transaction.cashier_name;
    this.customer_name = transaction.customer_name;
    this.transaction_type = transaction.transaction_type;
    this.transaction_type_text = transaction.transaction_type_text;
    this.discount_value = transaction.discount_value;
    this.items_count = transaction.items_count;
    this.created_at = transaction.created_at;
    this.readable_payment_method = transaction.readable_payment_method;
    this.last_payment_id = transaction.last_payment_id;

    this.redeemed_amount = transaction.redeemed_amount;

    this.payment_id = transaction?.payment_id;
    this.auth_id = transaction?.auth_id;
    this.card_number = transaction?.card_number;
    this.card_name = transaction?.card_name;
    this.merchant_id = transaction?.merchant_id;

    // @ts-expect-error - we don't need to use the add method, so we skip validation
    this.paymentsManager.strategy.payments = transaction.payments;
    // @ts-expect-error - we don't need to use the add method, so we skip validation
    this.itemsManager.strategy.items = transaction.items;
  }

  printReceipt() {
    //   const receiptContent = `
    //   <html>
    //   <head>
    //     <title>Receipt</title>
    //   </head>
    //   <body>
    //     <h1>Receipt</h1>
    //     <p><strong>Transaction ID:</strong> ${this.id}</p>
    //     <p><strong>Unique Code:</strong> ${this.unique_code}</p>
    //     <p><strong>Status:</strong> ${this.readable_status}</p>
    //     <p><strong>Cashier:</strong> ${this.cashier_name} (${this.cashier_id})</p>
    //     <p><strong>Customer:</strong> ${this.customer_name}</p>
    //     <p><strong>Transaction Type:</strong> ${this.transaction_type_text}</p>
    //     <p><strong>Discount:</strong> ${this.discount_value}</p>
    //     <p><strong>Items Count:</strong> ${this.items_count}</p>
    //     <p><strong>Date:</strong> ${this.created_at}</p>
    //     <p><strong>Payment Method:</strong> ${this.readable_payment_method}</p>
    //     <p><strong>Last Payment ID:</strong> ${this.last_payment_id}</p>
    //     ${JSON.stringify(this)}
    //   </body>
    //   </html>
    // `;
    //   const newWindow = window.open('', '_blank');
    //   if (newWindow) {
    //     newWindow.document.open();
    //     newWindow.document.write(receiptContent);
    //     newWindow.document.close();
    //     newWindow.print();
    //     // setTimeout(window.close, 0);
    //   } else {
    //     toast.error(
    //       'Unable to open print window. Please check your popup settings.',
    //     );
    //   }

    publish('PRINT_TRANSACTION_RECEIPT', {
      completedTransaction: this,
      knetResponse: this.knetResponse,
    });
  }
}
