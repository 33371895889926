// Plugins
import './styles/index.css';
import VueBarcode from '@chenfengyuan/vue-barcode';
// Components
import App from './App.vue';
import MultiTab from './features/multi-tab/multi-tab.vue';
// Composables
import { createApp } from 'vue';
import { registerPlugins } from './plugins';
import { injectSDKConfig } from './services/api';

const bootstrap = (component) => {
  injectSDKConfig();
  const app = createApp(component);
  registerPlugins(app);
  app.component('barcode', VueBarcode);
  app.mount('#app');
};

// const excludedPaths = ['client-home'];
const currentPath = window.location.pathname;

if (!currentPath.includes('client') && typeof SharedWorker !== 'undefined') {
  const worker = new SharedWorker('/multi-tab-worker.js');

  worker.port.start();

  worker.port.postMessage('check-open');

  worker.port.onmessage = function (event) {
    switch (event.data) {
      case 'already-open':
        bootstrap(MultiTab);
        break;
      case 'focus-original':
        window.focus();
        break;
      case 'original-tab':
        bootstrap(App);
        break;
      default:
        break;
    }
  };

  window.addEventListener('beforeunload', () => {
    worker.port.close();
  });
} else {
  bootstrap(App);
}
