import { AddedPayment } from '@trolley/types';
import { Payment } from '@trolley/payments';
import { PaymentStrategy } from './types';
import { toast } from 'vue3-toastify';
import { ItemsManager } from '@trolley/items';
import { almostEqualOrMore } from '@trolley/utils';

export class SinglePaymentStrategy implements PaymentStrategy {
  payments: Payment[] = [];
  get totalPaidAmount() {
    return this.payments.reduce((acc, payment) => acc + payment.amount, 0);
  }
  get isSubmittable() {
    return almostEqualOrMore(this.totalPaidAmount, this.itemsManager.totalDue);
  }
  constructor(private itemsManager: ItemsManager) {}

  // validation rules:
  // 1. Payment amount should be equal or more than due amount
  addPayment(payment: AddedPayment) {
    if (almostEqualOrMore(payment.amount, this.itemsManager.totalDue)) {
      this.removePayment();
      this.payments.push(new Payment(payment));
      return;
    }

    const errorMessage =
      'Payment amount should be equal or more than due amount';
    toast.error(errorMessage);
    console.error(errorMessage);
    return;
  }

  removePayment() {
    this.payments.splice(0, this.payments.length);
  }
}
