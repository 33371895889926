// Function to set a cookie
function setCookie(key, value, expirationDays = 1) {
  return new Promise((resolve, reject) => {
    try {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + expirationDays);
      const cookieValue = `${encodeURIComponent(key)}=${encodeURIComponent(
        value,
      )}; expires=${expirationDate.toUTCString()}; path=/;`;
      document.cookie = cookieValue;
      resolve();
    } catch (error) {
      reject(error);
    }
  });
}

// Function to get a cookie by key
function getCookie(key) {
  const cookieName = `${encodeURIComponent(key)}=`;
  const cookieArray = document.cookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(cookieName) === 0) {
      return decodeURIComponent(
        cookie.substring(cookieName.length, cookie.length),
      )
    }
  }
}

// Function to update a cookie by key and new value
function updateCookie(key, newValue, expirationDays = 1) {
  return new Promise((resolve, reject) => {
    getCookie(key)
      .then((existingValue) => {
        if (existingValue !== null) {
          setCookie(key, newValue, expirationDays)
            .then(() => resolve())
            .catch((error) => reject(error));
        } else {
          resolve(); // Cookie doesn't exist, no update needed
        }
      })
      .catch((error) => reject(error));
  });
}

// Function to remove a cookie by key
function removeCookie(key) {
  return new Promise((resolve, reject) => {
    try {
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() - 1);
      document.cookie = `${encodeURIComponent(
        key,
      )}=; expires=${expirationDate.toUTCString()}; path=/;`;
      resolve();
    } catch (error) {
      reject(error);
    }
  });
}

export {updateCookie, setCookie, getCookie, removeCookie};

// Example usage:
// Set a cookie
//   setCookie('accessToken', 'your_token_value');

// Get a cookie by key
//   const token = getCookie('accessToken');
//   console.log(token);

// Update a cookie by key and new value
//   updateCookie('accessToken', 'new_token_value');
