import { PaymentsManager } from '@trolley/payments';
import { ItemsManager } from '@trolley/items';
import { ITransactionBase } from '@trolley/types';

export abstract class Transaction implements ITransactionBase {
  type_from = 0; // 0 = POS
  amount: number;
  itemsManager = new ItemsManager();
  sub_total: number;

  get items() {
    return this.itemsManager.items;
  }
  paymentsManager = new PaymentsManager(this.itemsManager);
  get payments() {
    return this.paymentsManager.payments;
  }

  redeem_amount_points: number;
  redeemed_amount: number;

  constructor(transaction: ITransactionBase) {
    this.redeem_amount_points = transaction.redeem_amount_points;
    this.redeemed_amount = transaction.redeemed_amount ?? 0;

    // completed
    this.sub_total = transaction.sub_total;
    this.amount = transaction.amount;
  }

  accept(visitor: ITransactionVisitor) {
    return visitor.visit(this);
  }
}

export interface ITransactionVisitor<T = void> {
  visit(transaction: Transaction): T;
}
