import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { Interceptors } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { DefaultService } from './services';
import { DiscountsService } from './services';
import { ItemsService } from './services';
import { OrdersService } from './services';
import { PaymentMethodsService } from './services';
import { QuickListService } from './services';
import { RefundsService } from './services';
import { SettingsService } from './services';
import { TransactionsService } from './services';
import { UsersService } from './services';
import { VoidReasonsService } from './services';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class OnlineTrolleyClient {
  public readonly default: DefaultService;
  public readonly discounts: DiscountsService;
  public readonly items: ItemsService;
  public readonly orders: OrdersService;
  public readonly paymentMethods: PaymentMethodsService;
  public readonly quickList: QuickListService;
  public readonly refunds: RefundsService;
  public readonly settings: SettingsService;
  public readonly transactions: TransactionsService;
  public readonly users: UsersService;
  public readonly voidReasons: VoidReasonsService;

  public readonly request: BaseHttpRequest;

  constructor(
    config?: Partial<OpenAPIConfig>,
    HttpRequest: HttpRequestConstructor = AxiosHttpRequest,
  ) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? 'https://{{domain}}',
      VERSION: config?.VERSION ?? '1.0.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
      interceptors: {
        request: new Interceptors(),
        response: new Interceptors(),
      },
    });

    this.default = new DefaultService(this.request);
    this.discounts = new DiscountsService(this.request);
    this.items = new ItemsService(this.request);
    this.orders = new OrdersService(this.request);
    this.paymentMethods = new PaymentMethodsService(this.request);
    this.quickList = new QuickListService(this.request);
    this.refunds = new RefundsService(this.request);
    this.settings = new SettingsService(this.request);
    this.transactions = new TransactionsService(this.request);
    this.users = new UsersService(this.request);
    this.voidReasons = new VoidReasonsService(this.request);
  }
}
