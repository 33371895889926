export const TRANSACTION_EVENTS = {
  CREATING: 'order.creating',
  CREATED: 'order.created',
  UPDATED: 'order.updated',
  DELETED: 'order.deleted',
  BACKED: 'order.backed',
  SUSPEND: 'order.suspend.ts',
  VOID: 'order.void',
  SUCCESS: 'order.success',
  PAID: 'order.paid',
  FAILED: 'order.failed',
};

// export const ordersTopic = new Topic<OrdersTopic>();
