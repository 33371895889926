import { type KnetResponse, sendPurchaseRequest } from '@trolley/knet';
import { trolleyClient } from '@trolley/api-sdk';
import { PAYMENT_METHODS } from '@trolley/types';
import { CompletedTransaction } from './completed-transaction';

export class AutoKnetTransactionHandler {
  static async processKnetPayment(transaction: CompletedTransaction) {
    if (!transaction?.payment_id) {
      return Promise.reject('No payment id');
    }
    const knetResponse = await sendPurchaseRequest(
      transaction.payment_id,
      transaction.amount,
    );
    this.logKnetResponse(transaction, knetResponse);
    transaction.knetResponse = knetResponse;
    Object.assign(transaction, this.extractDataFromKnetResponse(knetResponse));

    if (knetResponse.success) {
      return await this.updateKnetPayment(transaction, knetResponse);
    } else {
      return Promise.reject('Knet payment failed');
    }
  }

  private static async updateKnetPayment(
    transaction: CompletedTransaction,
    knetResponse: KnetResponse,
  ) {
    await trolleyClient.orders.postApiPosTransactionsUpdateById({
      id: transaction.id.toString(),
      requestBody: {
        payment_method_id: PAYMENT_METHODS.AUTO_KNET,
        ...this.extractDataFromKnetResponse(knetResponse),
      },
    });
  }

  private static logKnetResponse(
    transaction: CompletedTransaction,
    knetResponse: KnetResponse,
  ) {
    return trolleyClient.orders.postApiPosTransactionsLogErrorById({
      id: transaction.id.toString(),
      requestBody: {
        log: knetResponse,
      },
    });
  }

  static extractDataFromKnetResponse(knetResponse: KnetResponse) {
    return {
      auth_id: knetResponse.log['@AuthorizationNumber'],
      card_number: knetResponse.log['@CardNumber'],
      card_name: knetResponse.log['@EmvApplicationLabel'],
      merchant_id: knetResponse.log['@MerchantId'],
    };
  }
}
