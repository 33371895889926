import { onMounted, reactive, watch } from 'vue';
import { useBackendStatusCheck } from '../app-mode/check';
import { useSettingsCheck } from '../../modules/settings/health-check';
import { useItemsCheck } from '../items/check';
import { useUsersCheck } from '../users/check';
import { useQuickListCheck } from '../../modules/quick-list/health-check';
import { useVoidReasonsCheck } from '../void-reasons/check';
import { usePaymentMethodsCheck } from '../payment-methods/check';

export type HealthStatus = 'loading' | 'ready' | 'error';

interface CheckItem {
  label: string;
  status: HealthStatus;
  countable?: boolean;
  count?: number;
}

const checks = reactive([
  { label: 'Backend Health', status: 'loading', countable: false },
  { label: 'Settings', status: 'loading', countable: false },
  { label: 'Items', status: 'loading', countable: true, count: 0 },
  { label: 'Users', status: 'loading', countable: true, count: 0 },
  { label: 'Quick List', status: 'loading', countable: true, count: 0 },
  { label: 'Void Reasons', status: 'loading', countable: true, count: 0 },
  { label: 'Payment Methods', status: 'loading', countable: true, count: 0 },
] as const);

export type CheckLabel = (typeof checks)[number]['label'];
const getCheck = (label: CheckLabel) => {
  return checks.find((check) => check.label == label) as CheckItem;
};

export const useHealthCheck = () => {
  const runCheck = async (
    checkFunction: () => Promise<any>,
    label: CheckLabel,
  ) => {
    const check = await checkFunction();
    const health = getCheck(label);

    watch(
      check.status,
      (newStatus) => {
        health.status = newStatus;
      },
      { immediate: true },
    );

    if ('count' in check && 'count' in health) {
      watch(
        check.count,
        (newCount) => {
          health.count = newCount;
        },
        { immediate: true },
      );
    }
  };

  onMounted(async () => {
    await Promise.all([
      runCheck(useBackendStatusCheck, 'Backend Health'),
      runCheck(useSettingsCheck, 'Settings'),
      runCheck(useItemsCheck, 'Items'),
      runCheck(useUsersCheck, 'Users'),
      runCheck(useQuickListCheck, 'Quick List'),
      runCheck(useVoidReasonsCheck, 'Void Reasons'),
      runCheck(usePaymentMethodsCheck, 'Payment Methods'),
    ]);
  });

  return {
    checks,
  };
};
