import {
  KNET_CONNECTION_COMMANDS,
  KNET_CONNECTION_STATE,
  type KnetResponse,
} from '@trolley/knet';
import { knetMachineTopic } from './events';

let socketService: WebSocket | null = null;
let ping: NodeJS.Timeout | undefined;

export function sendInitSignal() {
  if (socketService) {
    socketService.send(JSON.stringify({ method: 'INIT' }));
  }
}

knetMachineTopic.subscribe('COMMANDS', (command) => {
  switch (command) {
    case KNET_CONNECTION_COMMANDS.RECONNECT:
      initKnetSocketService();
      break;
    case KNET_CONNECTION_COMMANDS.DISCONNECT:
      socketService?.close();
      break;
    case KNET_CONNECTION_COMMANDS.PING:
      if (socketService?.readyState === WebSocket.OPEN)
        socketService?.send(JSON.stringify({ method: 'PING' }));
      break;
  }
});

export function initKnetSocketService() {
  knetMachineTopic.publish('STATUS', KNET_CONNECTION_STATE.CONNECTING);
  socketService = new WebSocket('ws://localhost:9999/es');

  socketService.onopen = () => {
    knetMachineTopic.publish('STATUS', KNET_CONNECTION_STATE.CONNECTED);

    sendInitSignal();

    ping = setInterval(() => {
      socketService?.send(JSON.stringify({ method: 'PING' }));
    }, 20000);
  };

  socketService.onmessage = (e) => {
    const message: KnetResponse = JSON.parse(e.data);
    handleWebSocketMessage(message);
  };

  socketService.onerror = () => {
    knetMachineTopic.publish('STATUS', KNET_CONNECTION_STATE.ERROR);
  };

  socketService.onclose = () => {
    knetMachineTopic.publish('STATUS', KNET_CONNECTION_STATE.DISCONNECTED);
    if (ping) clearInterval(ping);

    setTimeout(initKnetSocketService, 1000);
  };

  return socketService;
}

function handleWebSocketMessage(message: KnetResponse) {
  switch (message.method) {
    // case 'PURCHASE':
    //   if (message.success) {
    //     knetMachineTopic.publish('SUCCESS', message);
    //   } else {
    //     knetMachineTopic.publish('DECLINED', message);
    //   }
    //   knetMachineTopic.publish('RESPONSE', message);
    //   break;
    case 'PONG':
      knetMachineTopic.publish('STATUS', KNET_CONNECTION_STATE.PONG);
      break;
    case 'DISCONNECT':
      knetMachineTopic.publish('STATUS', KNET_CONNECTION_STATE.DISCONNECTED);
      break;
    case 'RECONNECT':
      sendInitSignal();
      break;
    default:
      console.log(message);
  }
}

export function queuePurchaseRequest(
  paymentId: string | number,
  amount: number,
) {
  const request = generatePurchaseMessage(paymentId, amount);

  switch (socketService?.readyState) {
    case WebSocket.OPEN:
      socketService.send(request);
      return;
    case WebSocket.CONNECTING:
      knetMachineTopic.publish('ERROR', {
        message: 'The device is still connecting, please wait',
      });
      return;
    default:
      knetMachineTopic.publish('ERROR', {
        message: 'Cannot send purchase request, Device is not connected',
      });
  }
}

export function sendPurchaseRequest(
  paymentId: string | number,
  amount: number,
): Promise<KnetResponse> {
  return new Promise((resolve, reject) => {
    if (!socketService || socketService.readyState !== WebSocket.OPEN) {
      reject(new Error('Device is not connected'));
      return;
    }

    const timeout = setTimeout(
      () => {
        cleanup();
        reject(new Error('Knet Machine response timeout'));
      },
      5 * 60 * 1000,
    ); // 5 minutes

    const handlePurchaseMessage = (event: MessageEvent) => {
      const message: KnetResponse = JSON.parse(event.data);
      if (message.method === 'PURCHASE') {
        resolve(message);
        cleanup();
      }
    };

    const cleanup = () => {
      clearTimeout(timeout);
      socketService?.removeEventListener('message', handlePurchaseMessage);
    };

    socketService.addEventListener('message', handlePurchaseMessage);

    socketService.send(generatePurchaseMessage(paymentId, amount));
  });
}

const generatePurchaseMessage = (
  paymentId: string | number,
  amount: number,
) => {
  return JSON.stringify({
    method: 'PURCHASE',
    transaction_id: paymentId,
    amount: amount.toFixed(3),
  });
};
