<script setup lang="ts">
import { ref, watch } from 'vue';
import BaseDialog from '../../components/dialogs/base-dialog.vue';
import { useHealthCheck } from './use-health-check';
import { publish } from '@enegix/events';
import { removeAllSyncHealthChecks } from '../../utils/sync';
import { useLocalStorage } from '@vueuse/core';

const dialogVisible = ref(false);
const isSyncCheckDialogClosed = useLocalStorage(
  'sync-check-dialog-confirmed',
  false,
);

watch(
  isSyncCheckDialogClosed,
  (value) => {
    dialogVisible.value = !value;
  },
  {
    immediate: true,
  },
);

const { checks } = useHealthCheck();
const handleRemoveCache = () => {
  removeAllSyncHealthChecks();
  publish('clear-cache-voidReasons');
  publish('clear-cache-quickList');
  publish('clear-cache-settings');
  publish('clear-cache-items');
  publish('clear-cache-paymentMethods');
  publish('clear-cache-users');

  setTimeout(() => window.location.reload(), 1000);
};

const handleConfirm = () => {
  dialogVisible.value = false;
  isSyncCheckDialogClosed.value = true;
};
</script>

<template>
  <base-dialog
    persistent
    v-model="dialogVisible"
    header-text="POS Health Check"
  >
    <div class="info-container mt-6 px-4">
      <div v-for="check in checks" :key="check.label" class="check-item">
        <p>
          <strong>{{ check.label }}</strong>
          <!--          <span v-if="check.countable"> - ({{ check.count }})</span>-->
        </p>
        <transition name="fade">
          <span v-if="check.status === 'loading'" class="status-icon loading">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              class="spinner"
            >
              <circle
                cx="50"
                cy="50"
                r="45"
                stroke="currentColor"
                stroke-width="10"
                fill="none"
                stroke-dasharray="283"
                stroke-dashoffset="75"
              />
            </svg>
          </span>
          <span v-else-if="check.status === 'ready'" class="status-icon ready">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path
                d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7 19.59 5.59 9 16.17z"
              />
            </svg>
          </span>
          <span v-else class="status-icon error">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z"
              />
            </svg>
          </span>
        </transition>
      </div>

      <div class="buttons">
        <v-btn
          @click="handleRemoveCache"
          width="50%"
          color="second"
          variant="tonal"
        >
          Re-sync
        </v-btn>
        <v-btn
          :disabled="!checks.every((check) => check.status === 'ready')"
          @click="handleConfirm"
          width="50%"
          color="primary"
        >
          Confirm
        </v-btn>
      </div>
    </div>
  </base-dialog>
</template>

<style scoped>
.buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.info-container {
  max-height: 60vh;
  width: 40vw;
  overflow-y: auto;
}

.check-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding-inline: 8px;
}

.status-icon {
  font-size: 1.5rem;
  margin-left: 1rem;
}

.status-icon.loading svg {
  width: 24px;
  height: 24px;
  color: #f39c12;
  animation: spin 1s linear infinite;
}

.status-icon.ready svg {
  width: 24px;
  height: 24px;
  color: #2ecc71;
}

.status-icon.error svg {
  width: 24px;
  height: 24px;
  color: #e74c3c;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
