import { computed, ref, watch } from 'vue';
import { usePendingTransactionStore } from '@trolley/transactions/vue';
import { whenever } from '@vueuse/core';
import { logicAnd } from '@vueuse/math';
import router from '../../router';
import { toast } from 'vue3-toastify';
import { resolveAfterDelay } from '@trolley/utils';
import { useRegisterSW } from 'virtual:pwa-register/vue';
import { appVersion, latestVersion } from './store';

export interface UpdateStep {
  title: string;
  status: 'pending' | 'progress' | 'completed' | 'error';
}

export enum UpdateSteps {
  UNREGISTERING = 'Unregistering old service workers',
  CLEARING_CACHE = 'Clearing cache',
  REGISTERING = 'Registering new version',
  RELOADING = 'Reloading application',
}

const url = new URL(window.location.href);

const { updateServiceWorker, needRefresh } = useRegisterSW({});

export const showVersionUpdateDialog = ref(false);

export const versionUpdateFeature = async () => {
  const isCartEmpty = computed(
    () => usePendingTransactionStore().itemsManager.totalItems === 0,
  );
  const needsUpdate = ref(false);

  watch(
    [latestVersion, appVersion],
    () => {
      if (!latestVersion.value) return;

      if (latestVersion.value !== appVersion.value) {
        needsUpdate.value = true;
      }
    },
    { immediate: true },
  );

  whenever(logicAnd(needRefresh, isCartEmpty), updateServiceWorker, {
    immediate: true,
  });

  whenever(
    logicAnd(needsUpdate, isCartEmpty),
    () => {
      showVersionUpdateDialog.value = true;
    },
    { immediate: true },
  );

  toast(`IAM WORKING`);

  const showSystemUpdateToast = localStorage.getItem('systemUpdated');
  if (showSystemUpdateToast) {
    toast('System updated successfully');
    localStorage.removeItem('systemUpdated');
  }

  // If there is no service worker, inject one on load
  window.addEventListener('load', async () => {
    const count = (await navigator.serviceWorker.getRegistrations()).length;
    if (!count) await injectServiceWorker();
  });
};

export const assignNewAppVersion = () => {
  appVersion.value = latestVersion.value;
  url.searchParams.set('updateServiceWorker', latestVersion.value);

  if (latestVersion.value) {
    url.pathname = `/${latestVersion.value}/cart`;
  } else {
    url.pathname = `/cart`;
  }

  localStorage.setItem('systemUpdated', 'true');

  return router.push({ path: url.pathname });
};

const unregisterServiceWorker = async (
  registrations: Readonly<ServiceWorkerRegistration[]>,
) => {
  const unregisterPromises = registrations.map((registration) =>
    registration.unregister(),
  );
  await Promise.all(unregisterPromises);

  const cacheNames = await caches.keys();
  const deletePromises = cacheNames.map((cacheName) => {
    console.log('deleting cache', cacheName);
    return caches.delete(cacheName);
  });
  await Promise.all(deletePromises);
};
const injectServiceWorker = async () => {
  const versionScope = latestVersion.value ? `/${latestVersion.value}/` : '/';

  navigator.serviceWorker.addEventListener('controllerchange', () => {
    router.push({ path: url.pathname }).then(() => {
      window.location.reload();
    });
  });

  navigator.serviceWorker.register(`${versionScope}sw.js`).catch((error) => {
    console.error('ServiceWorker registration failed:', error);
  });
};

const handleStep = async (task: () => Promise<void>, step: UpdateSteps) => {
  currentStep.value = step;
  updateStepStatus(step, 'progress');
  try {
    await resolveAfterDelay(task());
    updateStepStatus(step, 'completed');
  } catch {
    updateStepStatus(step, 'error');
  }
};

export const updateSteps = ref<UpdateStep[]>([]);
export const updateError = ref<string | null>(null);
export const currentStep = ref<UpdateSteps | null>(null);

export const updateVersion = async () => {
  updateError.value = null;
  updateSteps.value = Object.values(UpdateSteps).map((title) => ({
    title,
    status: 'pending' as const,
  }));

  try {
    await handleStep(async () => {
      const registrations = await navigator.serviceWorker.getRegistrations();
      await unregisterServiceWorker(registrations);
    }, UpdateSteps.UNREGISTERING);

    await handleStep(clearCaches, UpdateSteps.CLEARING_CACHE);

    await handleStep(injectServiceWorker, UpdateSteps.REGISTERING);

    await assignNewAppVersion();

    await handleStep(async () => {
      window.location.reload();
    }, UpdateSteps.RELOADING);
  } catch (error) {
    toast('Update failed');
    console.error('Update failed:', error);
  }
};

const updateStepStatus = (step: UpdateSteps, status: UpdateStep['status']) => {
  const index = updateSteps.value.findIndex((s) => s.title === step);
  if (index >= 0) {
    updateSteps.value[index].status = status;
  }
};

const clearCaches = async () => {
  caches.keys().then((cacheNames) => {
    return Promise.all(
      cacheNames.map((cacheName) => {
        console.log('Deleting cache:', cacheName);
        return caches.delete(cacheName);
      }),
    );
  });
};
