export function toFloat(value: string | number, decimals = 3): number {
  const num = parseFloat(value.toString());

  if (isNaN(num)) {
    console.error('Invalid input. Please provide a valid number.');
  }

  return parseFloat(num.toFixed(decimals));
}

export function formatPrice(
  rawPrice: number | undefined | string,
  isReady = true,
): string {
  if (!isReady) {
    return '-.---';
  }

  if (rawPrice === undefined) {
    return 'Price not available';
  }

  const price = toFloat(rawPrice);

  if (isNaN(price)) {
    console.error('Invalid input. Please provide a valid price as a number.');
    return 'Invalid input. Please provide a valid price as a number.';
  }

  let formattedPrice: string = price.toFixed(3);

  // Add commas for thousands separator
  formattedPrice = formattedPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return formattedPrice;
}
