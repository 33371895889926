import { subscribe, Topic } from '@enegix/events';
import { trolleyClient } from '@trolley/api-sdk';

export enum BACKEND_STATUS {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

type AppModeTopicType = {
  STATUS: BACKEND_STATUS;
  CHECK: void;
};

// 10 seconds
const BACKEND_CHECK_INTERVAL = 10000;

export const BackendStatusTopic = new Topic<AppModeTopicType>();

export const initAppMode = () => {
  checkBackendHealth();
};

export const checkBackendHealth = () => {
  trolleyClient.settings
    .getApiPosHealthCheck()
    .then(() => {
      BackendStatusTopic.publish('STATUS', BACKEND_STATUS.ONLINE);
    })
    .catch(() => {
      BackendStatusTopic.publish('STATUS', BACKEND_STATUS.OFFLINE);
    });
};

export const setupAppModeWatchers = () => {
  checkBackendHealth();

  setInterval(checkBackendHealth, BACKEND_CHECK_INTERVAL);

  BackendStatusTopic.subscribe('CHECK', checkBackendHealth);

  subscribe('backend-error', () => {
    BackendStatusTopic.publish('CHECK');
  });
};
