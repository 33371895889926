import { db } from '@trolley/db';

export const getCurrentLocalSession = () =>
  db.sessions.orderBy('started_at').last();

export const getCurrentCashier = async () => {
  const currentSession = await getCurrentLocalSession();
  // TODO: why are we returning 'Unknown' here?, should we throw an error?
  if (!currentSession?.pos_user_id) return 'Unknown';

  return db.users.get(currentSession.pos_user_id);
};
