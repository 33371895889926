<template>
  <router-view />
  <changeLangDemo />

  <transition name="freeze">
    <div v-if="freezeUi" class="freeze-ui"></div>
  </transition>

  <sync-check-dialog v-if="!isClientHomePage" />
</template>
<script setup lang="ts">
// noinspection TypeScriptCheckImport
import ChangeLangDemo from './components/changeLangDemo.vue';
import { onMounted, ref } from 'vue';
import { subscribe } from '@enegix/events';
import SyncCheckDialog from './features/sync-check/sync-check-dialog.vue';
import { isClientHomePage } from './utils/path';
import preAuthFeatures from './features/pre-auth-features';

const freezeUi = ref(false);
subscribe('FREEZE_UI', () => {
  freezeUi.value = true;
});
subscribe('UNFREEZE_UI', () => {
  freezeUi.value = false;
});

const bytesToMb = (bytes: number | undefined) => {
  if (!bytes) return 0;
  return bytes / 1024 / 1024;
};

navigator.storage.estimate().then((estimate) => {
  // from bytes to gb
  console.log(`Quota: ${bytesToMb(estimate.quota)} MB`);
  console.log(`Usage: ${bytesToMb(estimate.usage)} MB`);
});

let usedBytes = new Blob(Object.values(localStorage)).size;
console.log(`LocalStorage Usage: ${bytesToMb(usedBytes)} MB`);
console.log('THIS IS FROM V2');

// useUiUpdate().check();
onMounted(() => {
  if (!isClientHomePage) {
    preAuthFeatures.bootstrap();
  }
});
</script>
<style scoped>
/* Freeze UI */
.freeze-ui {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
}

/* Transition classes */
.freeze-enter-active,
.freeze-leave-active {
  transition:
    opacity 0.5s ease,
    backdrop-filter 0.5s ease;
}

.freeze-enter-from,
.freeze-leave-to {
  opacity: 0;
  backdrop-filter: blur(0);
}
</style>
