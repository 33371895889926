import { sha1 } from 'js-sha1';
import { CancelablePromise } from '../online-client';
import {
  CreateTransactionPayload,
  ITransactionResponse,
  TRANSACTION_STATUS,
} from '@trolley/types';
import { db, getCurrentCashier } from '@trolley/db';
import { SYNC_INTERVAL } from '../constants';
import { uniqueCode } from './unique-code-generator';

export const isEmpty = (value: unknown[]) => value.length === 0;

export function generateSyncKey({
  terminalId,
  posSyncKey,
}: {
  terminalId: string;
  posSyncKey: string;
}) {
  return sha1.hmac(posSyncKey, terminalId);
}

export type BackendResponse<T> = {
  data: T;
  success: boolean;
};

export const generateBackendResponse = <T>(data: T) => ({
  data,
  success: true,
});

export const fetchAllPagesMethodFactory = (
  fetcher: (props?: any) => CancelablePromise<BackendResponse<[]>>,
  shouldStop = (data: any) => data.length === 0,
): (() => CancelablePromise<BackendResponse<any[]>>) => {
  return () =>
    new CancelablePromise(async (resolve, reject, onCancel) => {
      let allData: any[] = [];
      let page = 1;
      let isCanceled = false;

      const cancelHandler = () => {
        isCanceled = true;
        reject(new Error('Fetching was canceled.'));
      };

      onCancel(cancelHandler);

      try {
        while (!isCanceled) {
          const response = await fetcher({ p: page });
          if (isCanceled) break;

          const data = response.data;

          if (shouldStop(data)) break;

          allData = allData.concat(data);
          page += 1;
        }
        resolve(generateBackendResponse(allData));
      } catch (error) {
        if (!isCanceled) {
          reject(error);
        }
      }
    });
};

export const getCachedEntity = async (entity: string) =>
  // @ts-expect-error - we are sure that the entity exists
  await db[entity].toArray();

// TODO: refactor this by extracting the logic to a separate functions
export async function adaptLocalOrder(
  transaction: CreateTransactionPayload,
): Promise<ITransactionResponse> {
  const generatedUniqueCode = uniqueCode.generate();

  const cashier = await getCurrentCashier();
  if (!cashier || cashier == 'Unknown') {
    throw new Error('Cashier not found, please login again.');
  }

  return {
    created_at: getCurrentFormattedTime(),
    items_count: transaction.items.length,
    // TODO: find out why we need to convert the array to a new array
    // @ts-expect-error - id has to be string
    id: generatedUniqueCode,
    local_id: generatedUniqueCode,
    unique_code: generatedUniqueCode,

    isLocal: true,

    cashier_id: cashier.username,
    cashier_name: cashier.name,

    // this gets overwritten by data in the transaction
    status: TRANSACTION_STATUS.PAID,
    readable_status: 'Paid',
    ...transaction,

    // this is a MUST to extract the items and payments
    // from the ref proxy
    items: Array.from(transaction.items),
    payments: Array.from(transaction.payments),
  };
}

function getCurrentFormattedTime(): string {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');

  let hours = now.getHours();
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12;
  const formattedHours = String(hours).padStart(2, '0');

  return `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
}

export const isLocalRecord = (record: any) => record.isLocal;

export const startInterval = (callback: any, interval = SYNC_INTERVAL) => {
  return setInterval(callback, interval);
};

export const decodeBase64 = (base64: string) =>
  Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
