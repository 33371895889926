export interface IVoidReason {
  id: number;
  name: string;
  name_ar: string;
}

export interface ILocalOrder {
  id: number;
  customer_id: number;
  isLocal: boolean;
  items: { id: number; quantity: number }[];
  status: number;
  payments: {
    id: number;
    payment_method_id: number;
    amount: number;
    extra: string;
    readable_payment_method: string;
  }[];
  rating: number | undefined;
  redeem_amount_pointsredeem_amount: string;
  redeemed_amount: number;
  type_from: number;
  amount: number;
}

// TODO: try to make order data and transaction data the same
export interface OrderData {
  id: number;
  unique_code: string;
  card_name: string;
  card_number: string;
  merchant_id: string;
  auth_id: string;
  payment_method_id: number;
  readable_payment_method: string;
  payment_id: string;
  type_from: number;
  payments: OrderSuccessPaymentMethod[];
  readable_typefrom: string;
  transaction_type: number;
  status: number;
  readable_status: string;
  sub_total: number;
  amount: number;
  discount_value: number;
  amount_before_discount: number;
  redeemed_amount: number;
  redeem_amount_points: number;
  items_count: number;
  items: SharableProduct[];
  created_at: string;
  extra: string;
}

export type Session = OnlineSession | OfflineSession;

export type OfflineSession = {
  id: number;
  type: SESSION_TYPE;
  cash_in?: number;
  cash_out?: number;
  end_knet?: number;
  started_at?: string;
  ended_at?: string;
  pos_user_id?: number;
};

export type OnlineSession = {
  id: number;
  type: SESSION_TYPE;
};

export enum SESSION_TYPE {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export interface IUser {
  id: number;
  warehouse_id: number;
  name: string;
  username: string;
  password: string;
}

export interface Transaction {
  id: number;
  unique_code: string;
  payment_method_id: number;
  payment_id: string;
  type_from: number;
  readable_typefrom: string;
  status: TRANSACTION_STATUS;
  readable_status: string;
  cashier_name: string;
  customer_name: string;
  card_number: string;
  amount: number;
  sub_total: number;
  discount_value: number;
  amount_before_discount: number;
  redeemed_amount: number;
  redeem_amount_points: number;
  extra: string;
  items_count: number;
  items: IProduct[];
  created_at: string;
  transaction_type: TRANSACTION_TYPE;
}

interface SharableProduct {
  id: number;
  name: string;
  name_ar: string;
  price: number;
  price_before_offer: number;
  quantity: number;
}

type OrderSuccessPaymentMethod = {
  id: number;
  payment_method_id: number;
  readable_payment_method: string;
  amount: number;
  payment_id: string;
  auth_id: any;
  merchant_id: any;
  card_number: string;
  card_name: string;
  extra: string;
};

export interface IProduct {
  id: number;
  name: string;
  name_ar: string;
  price: number;
  price_before_offer: number;
  redeemable: boolean;
  barcodes: Record<
    string,
    {
      id: string;
      item_id: string;
      quantity: number;
    }
  >;
  computed: {
    quantity: number;
    discount: number;
    upc: string;
  };
  quantity?: number;
}

export interface Category {
  id: number;
  name: string;
  name_ar: string;
  items: IItem[];
}

export interface CachedPaymentMethod {
  id: number;
  name: string;
  name_ar: string;
  slug: string;
  requires_code: boolean;
  splittable: boolean;
  repeatable: boolean;
  requires_cash: boolean;
  is_scanned: boolean;
  loyalty_support: boolean;
  payment_method_id: number;

  // adapted
  disabled: boolean;
  inputName: string;
  readable_payment_method: string;
}

export interface ISettings {
  id: number;
  settings: {
    earn_factor: number;
    skip_payment: boolean;
  };
  features: any[];
  warehouse: string;
  warehouse_id: number;
  terminal_id: string;
  terminal_name: string;
  sequence: string;
  version: string;
  is_strict_version: boolean;
}

// =================================================================

export enum PAYMENT_METHODS {
  CASH = 1,
  AUTO_KNET = 2,
  MANUAL_KNET = 5,
  TALABAT = 6,
  DELIVEROO = 7,
}

export interface IPaymentBase {
  id: number;
  payment_method_id: number;
  amount: number;
  extra: any;
  readable_payment_method: string;
}

export interface AddedPayment extends IPaymentBase {
  loyalty_support: boolean;
  splittable: boolean;
  repeatable: boolean;
  requires_cash: boolean;
  requires_code: boolean;
  is_scanned: boolean;
}

export interface IPaymentResponse extends IPaymentBase {
  payment_id?: any;
  auth_id?: any;
  merchant_id?: any;
  card_number?: string;
  card_name?: string;
}

// Common Transaction Fields
export interface ITransactionBase {
  amount: number;
  sub_total: number;
  // amount_before_discount: number;
  redeem_amount_points: number;
  redeemed_amount: number;
  items: IItem[];
  payments: IPaymentBase[];
}

// Online Transaction Payload
export interface OnlineTransactionPayload extends ITransactionBase {
  customer_id: number;
  phone: string;
  rating: any;
  commit_hash: string;
}

// Online Transaction Response
export interface ITransactionResponse extends ITransactionBase {
  id: number;
  unique_code: string;
  status: TRANSACTION_STATUS;
  readable_status: string;
  cashier_id: string;
  cashier_name: string;
  customer_name: string;
  transaction_type: number;
  transaction_type_text: string;
  discount_value: number;
  items_count: number;
  created_at: string;
  readable_payment_method: string;
  last_payment_id: number;
  payments: IPaymentResponse[];
  items: IItem[];

  auth_id?: string;
  merchant_id?: string;
  card_number?: string;
  card_name?: string;
  extra?: string;
  payment_id?: number;
}

// Offline Transaction Payload
export interface OfflineTransactionPayload extends ITransactionBase {
  id: string;
  cashier_id: string;
  cashier_name: string;
  created_at: string;
  discount_value: number;
  isLocal: boolean;
  items_count: number;
  local_id: string;
  rating: any;
  readable_status: string;
  readable_typefrom: string;
  session_id: number;
  status: TRANSACTION_STATUS;
  transaction_type: TRANSACTION_TYPE;
  unique_code: string;
  payments: IPaymentBase[];
  commit_hash: string;
}

export enum TRANSACTION_STATUS {
  PAID = 1,
  NOT_PAID = 2,
  VOIDED = 3,
}

export enum TRANSACTION_TYPE {
  SALE = 1,
  REFUND = 2,
}

export interface IBarcode {
  id: string;
  item_id: number;
  quantity: number;
}

export interface IItem {
  id: number;
  name: string;
  name_ar: string;
  price: number;
  price_before_offer: number;
  redeemable: boolean;
  barcodes: Record<string, IBarcode>;
  quantity: number;
  // only generated in the cached item
  barcodeIds: string[];
}

export interface IActiveTransaction extends ITransactionBase {
  isSubmitting: boolean;
  isRefund: boolean;
  coupon: string;
}

export interface ICoupon {
  code: string;
  discount_text: string;
  value: number;
  type: number;
  type_text: string;
  min_amount: number;
  include_passive: number;
  end_date: string;
  has_items: number;
  has_categories: number;
  has_warehouses: number;
  is_staff: number;
  note: string;
}

export type CreateTransactionPayload = Pick<
  IActiveTransaction,
  'items' | 'payments' | 'coupon' | 'amount' | 'sub_total'
> & {
  type_from: number;
  customer_id: number;
  redeem_amount: number;
  phone: string;
  redeem_amount_points: number;
};
