const abs = Math.abs;
const min = Math.min;

export function almostEqualOrMore(
  a: number,
  b: number,
  absoluteError: number = almostEqualOrMore.DBL_EPSILON,
  relativeError: number = absoluteError,
): boolean {
  const difference = abs(a - b);

  // Check if the difference is within the absolute error
  if (difference <= absoluteError) {
    return true;
  }

  // Check if the difference is within the relative error, using the smallest of the two values for comparison
  if (difference <= relativeError * min(abs(a), abs(b))) {
    return true;
  }

  // Return true if 'a' is greater than 'b'
  if (a > b) {
    return true;
  }

  return false;
}

export function almostEqual(
  a: number,
  b: number,
  absoluteError: number = almostEqualOrMore.DBL_EPSILON,
  relativeError: number = absoluteError,
): boolean {
  const difference = abs(a - b);

  // Check if the difference is within the absolute error
  if (difference <= absoluteError) {
    return true;
  }

  // Check if the difference is within the relative error, using the smallest of the two values for comparison
  if (difference <= relativeError * min(abs(a), abs(b))) {
    return true;
  }

  return false;
}

// Constants for floating-point precision
almostEqualOrMore.FLT_EPSILON = 1.1920929e-7; // Single-precision float (32-bit)
almostEqualOrMore.DBL_EPSILON = 2.2204460492503131e-16;  // Double-precision float (64-bit)
