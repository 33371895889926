import type { HealthStatus } from '../../features/sync-check/use-health-check';
import { ref } from 'vue';
import { z } from 'zod';
import { itemSchema } from '../../features/items/check';
import { getCachedEntity } from '@trolley/api-sdk';
import { useQuickList } from './store';

export const quickListSchema = z.object({
  id: z.number(),
  name: z.string(),
  name_ar: z.string(),
  // bc barcodeIds is generated by the database, it is not present in the quick list
  items: z.array(itemSchema.omit({ barcodeIds: true })),
});

export const useQuickListCheck = async () => {
  const status = ref<HealthStatus>('loading');
  const count = ref(0);

  const quickList = await getCachedEntity('quickList');
  if (!quickList.length) useQuickList().fetch();

  const quickListHealth = Promise.all(
    quickList.map((item) => quickListSchema.safeParseAsync(item)),
  );

  const results = await quickListHealth;

  status.value = results.every((result) => {
    if (!result.success) {
      console.error(result.error);
    }
    return result.success;
  })
    ? 'ready'
    : 'error';
  count.value = results.length;

  return {
    status,
    label: 'Quick List' as const,
    count,
  };
};
