import { defineStore } from 'pinia';
import type { Category, IItem } from '@trolley/types';
import { trolleyClient } from '@trolley/api-sdk';

export const useQuickList = defineStore('quickList', {
  state: () => ({
    loading: true,
    categories: [] as Category[],
    selectedCategory: null as Category | null,
  }),
  getters: {
    items(): IItem[] {
      if (!this.selectedCategory) return [];
      return this.selectedCategory.items;
    },
  },
  actions: {
    fetch() {
      trolleyClient.quickList
        .getApiPosQuickListCategories()
        .then(({ data }) => {
          if (!data) return;
          this.categories = data as Category[];

          const selectedCategory = this.categories.find(
            (category) => category.id === this.selectedCategory?.id,
          );

          if (selectedCategory) this.selectedCategory = selectedCategory;
          else this.select(this.categories[0].id);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    select(id: number) {
      const targetCategory = this.categories.find(
        (category) => category.id === id,
      );
      if (!targetCategory) return;

      this.selectedCategory = targetCategory;
    },
  },
});
