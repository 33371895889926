import { z } from 'zod';
import type { HealthStatus } from '../sync-check/use-health-check';
import { ref } from 'vue';
import { useVoidReasons } from './store';
import { getCachedEntity } from '@trolley/api-sdk';

export const voidReasonSchema = z.object({
  id: z.number(),
  name: z.string(),
  name_ar: z.string(),
});

export const useVoidReasonsCheck = async () => {
  const status = ref<HealthStatus>('loading');
  const count = ref(0);

  const voidReasons = await getCachedEntity('voidReasons');
  if (!voidReasons.length) await useVoidReasons().fetchVoidReasons();

  const voidReasonsHealth = Promise.all(
    voidReasons.map((item) => voidReasonSchema.safeParseAsync(item)),
  );

  const results = await voidReasonsHealth;

  status.value = results.every((result) => {
    if (!result.success) {
      console.error(result.error);
    }
    return result.success;
  })
    ? 'ready'
    : 'error';
  count.value = results.length;

  return {
    status,
    label: 'Void Reasons' as const,
    count,
  };
};
