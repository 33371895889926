import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import type { Auth } from './types';
import { subscribe } from '@enegix/events';
import { AUTH_EVENTS } from './events';
import { formatPrice } from '@trolley/utils';
import { useLocalStorage } from '@vueuse/core';
// note: refactoring this to pinia preserve state, will break change
// as current logged-in users will need to re-login after refresh
const AUTH_KEY = 'auth';
export const useAuthStore = defineStore('Auth', () => {
  const cachedAuth = JSON.parse(localStorage.getItem(AUTH_KEY) ?? 'null');

  const auth = ref<Auth | null>(cachedAuth ?? null);
  const endOfDay = ref(false);
  const knet_out = ref(0);
  const cash_out = ref(0);
  const sessionStarted = useLocalStorage('sessionStarted', false);

  watch(auth, (value) => {
    if (value) {
      localStorage.setItem('auth', JSON.stringify(value));
    } else localStorage.removeItem('auth');
  });

  watch(sessionStarted, (value) => {
    if (value) sessionStarted.value = true;
    else sessionStarted.value = false;
  });

  subscribe(AUTH_EVENTS.LOG_OUT, () => {
    auth.value = null;
    sessionStarted.value = false;
  });

  return {
    auth,
    endOfDay,
    sessionStarted,
    knet_out: computed({
      get: () => formatPrice(knet_out.value),
      set: (value: string) => {
        knet_out.value = parseFloat(value);
      },
    }),
    cash_out: computed({
      get: () => formatPrice(cash_out.value),
      set: (value: string) => {
        cash_out.value = parseFloat(value);
      },
    }),
  };
});
