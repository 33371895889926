// delaying all requests to give cashiers confident that the request is handled
//  https://www.uxmatters.com/mt/archives/2018/07/handling-delays.php
export const resolveAfterDelay = <T>(promise: Promise<T>, delay = 800) => {
  const start = Date.now();
  return new Promise<T>((resolve, reject) => {
    promise
      .then((result) => {
        const elapsed = Date.now() - start;
        const remainingDelay = Math.max(0, delay - elapsed);
        setTimeout(() => resolve(result), remainingDelay);
      })
      .catch((error) => {
        const elapsed = Date.now() - start;
        const remainingDelay = Math.max(0, delay - elapsed);
        setTimeout(() => reject(error), remainingDelay);
      });
  });
};
