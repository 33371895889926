import { z } from 'zod';
import { computed, ref, watch } from 'vue';
import type { HealthStatus } from '../../features/sync-check/use-health-check';
import { useSettings } from './store';
import { SyncHealthCheck } from '../../../../../libs/api-sdk/src/offline-client/interval-fetch-and-cache';

export const settingsSchema = z.object({
  settings: z.object({ earn_factor: z.number(), skip_payment: z.boolean() }),
  features: z.object({
    receipt_confirmation: z.boolean(),
    offline_mode: z.boolean(),
  }),
  warehouse: z.string(),
  warehouse_id: z.number(),
  terminal_id: z.string(),
  terminal_name: z.string(),
  sequence: z.string(),
  version: z.string(),
});

export const useSettingsCheck = async () => {
  const status = ref<HealthStatus>('loading');
  const settings = computed(() => useSettings().settings);

  if (!settings.value) await useSettings().fetchSettings();

  const validate = async () => {
    const parsedSettingsHealth = settingsSchema.safeParse(settings.value);
    status.value = parsedSettingsHealth.success ? 'ready' : 'error';
    if (!parsedSettingsHealth.success) {
      status.value = 'error';
      console.error(parsedSettingsHealth.error);
    }
  };

  watch(settings, validate, { immediate: true });
  watch(SyncHealthCheck, validate, { immediate: true, deep: true });

  return {
    status,
    label: 'Settings' as const,
  };
};
